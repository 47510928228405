import { Controller } from "@hotwired/stimulus";

// @see app/views/client_interactions/_index.html.erb for referce
// calling
// = link_to t('reminders.add'), [:new, client, :reminder], class: 'bigger_note text-icon icon-add', data: {turbo_frame: 'new_client_reminder' }
// = turbo_frame_tag :new_client_reminder

// = turbo_frame_tag :new_client_reminder do
//   %dialog#new_client_interaction{aria: {labelledby: 'new_client_reminder_title'}, role: 'dialog', tabindex: '-1', data: {controller: 'remote-dialog'}}
//     .dialog-content
//       %header.flex-row
//         %h2#new_client_reminder_title= t('reminders.add')
//         %a.close-icon{aria: {label: t('common.close')}, data: {action: 'click->remote-dialog#close'}, type: 'button', title: t('common.close')}
//           %span{aria: {hidden: 'true'}}=icon_close
//       = render 'form', client_interaction: @client_interaction

export default class extends Controller {
  connect() {
    this.element.showModal();
  }

  close() {
    this.element.close();
  }
}
