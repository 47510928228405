
import {Controller} from 'stimulus'

export default class extends Controller {
  connect() {
    this.toggleStatementKindDetails();
  }

  toggleStatementKindDetails() {
    if ($('#payer_verification_statement_kind_owner').is(':checked') && $('#payer_name_verified').val() == 'true') {
      $('#upload_document').hide();
    } else {
      $('#upload_document').show();
    }
  }
}
