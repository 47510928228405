import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ['toggle', 'typeLine', 'type', 'submit', 'setup']

  connect() {
    this.updateState()
  }

  updateState() {
    if (this.typeTarget.value == 'app') {
      this.submitTarget.value = this.submitTarget.dataset.app
      this.setupTarget.classList.remove('hidden')
      if (!this.setupTarget.dataset.loaded && this.typeTarget.dataset.original != 'app') {
        this.loadCode()
      }
    } else {
      this.submitTarget.value = this.submitTarget.dataset.sms
      this.setupTarget.classList.add('hidden')
    }

    if (this.toggleTarget.checked) {
      this.typeLineTarget.classList.remove('hidden')
    } else {
      this.typeLineTarget.classList.add('hidden')
      this.setupTarget.classList.add('hidden')
    }

    if (this.something_changed()) {
      this.submitTarget.removeAttribute('disabled')
      this.submitTarget.classList.remove('hidden')
    } else {
      this.submitTarget.disabled = true
      this.submitTarget.classList.add('hidden')
    }
  }

  loadCode() {
    let controller = this
    $.ajax({
      url: this.setupTarget.dataset.code,
      dataType: 'text',
      success: function (data) {
        controller.setupTarget.innerHTML = data;
        controller.setupTarget.dataset.loaded = true
      },
      error: function (e) {
        console.log(e);
      }
    })
  }

  something_changed() {
    let was_checked = this.toggleTarget.dataset.original == '1'
    return this.toggleTarget.checked != was_checked || (this.toggleTarget.checked && this.typeTarget.value != this.typeTarget.dataset.original)
  }
}
