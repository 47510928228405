import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['checkBox', 'totalUsed', 'overchargeLeft', 'submitButton', 'overchargeError'];
  static values = {
    overcharge: Number,
    currency: String
  }

  connect() {
    this.serialsOptions = {}
    this.formatter = new Intl.NumberFormat('cs-CZ', {
      style: 'currency',
      currency: this.currencyValue,
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    this.update();
  }

  update() {
    let checkBoxes = this.checkBoxTargets
    let overcharge = this.overchargeValue

    let used = 0
    checkBoxes.forEach(function(checkBox){
       used += checkBox.checked ? parseFloat(checkBox.dataset.leftToPay) : 0
    })
    this.totalUsedTarget.innerHTML = this.formatter.format(used)
    this.overchargeLeftTarget.innerHTML = this.formatter.format(overcharge - used)
    if (used > overcharge) {
      this.overchargeErrorTarget.classList.remove('hidden')
      this.submitButtonTarget.disabled = true
    } else if (used == 0) {
      this.submitButtonTarget.disabled = true
    } else {
      this.submitButtonTarget.disabled = false
      this.overchargeErrorTarget.classList.add('hidden')
    }

  }
}
