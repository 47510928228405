import {Controller} from "stimulus"

export default class extends Controller {
  static targets = [
    'item', 'client', 'currency', 'eshopClient', 'total', 'clientBankAccount', 'personType',
    'noContractClient', 'amlStatus', 'originalClientWrapper', 'originalClient', 'stockProductsChooser', 'clientBankAccounts',
    'clientBankAccount', 'stockProductsTransfers', 'product', 'stockProducts', 'buyoutInvoiceProductOriginalClient', 'clientBankAccountFields'
  ];
  static values = {
    damagedCharge: Object,
    buyoutAppointmentId: Number,
    itemTemplate: String,
    amlLimits: Object

  }

  connect() {
    this.client = this.clientTarget.value
    this.eshop_client = this.eshopClientTarget.value
    this.no_contract_client = this.noContractClientTarget.value
    this.last_options = {}

    this.initSuggests();
    this.updatePerson();
    this.updateItems();
  }

  updatePerson() {
    let value = 'client';
    this.personTypeTargets.forEach((el, i) => {
      if (el.checked) {
        value = el.value;
      }
    });

    $('.person_type_dependent').hide();
    $('.person_type_' + value).show();

    this.amlStatusTarget.innerHTML = '';

    this.reset_buyout_invoice_details();
    this.handleItemOrigianlClient(value !== 'buyout_trustee')
    if (value === 'client' || value === 'poa' || value === 'buyout_trustee') {
      this.eshopClientTarget.value = '';
      this.noContractClientTarget.value = '';
      if (!this.clientTarget.value) {
        this.clientTarget.value = this.client
      }
      this.updateClient();
    } else if (value === 'eshop_client') {
      this.clientTarget.value = '';
      this.noContractClientTarget.value = '';
      if (!this.eshopClientTarget.value) {
        this.eshopClientTarget.value = this.eshop_client
      }
      this.updateEshopClient();
    } else if (value === 'no_contract_client') {
      this.clientTarget.value = '';
      this.eshopClientTarget.value = '';
      if (!this.noContractClientTarget.value) {
        this.noContractClientTarget.value = this.no_contract_client
      }
      this.updateNoContractClient();
    }
  }

  updateItems() {
    let is_client = this.clientTarget.value.length > 0 || this.eshopClientTarget.value.length > 0
    let self = this
    this.itemTargets.forEach(function (item) {
      $(item).attr('data-buyout-item-client-value', is_client)
      $(item).attr('data-buyout-item-currency-value', $(self.currencyTarget).val())
    });
    setTimeout(function(){
      let total = window.update_total();
      if(total > self.amlLimitsValue[self.currencyTarget.value]) {
        self.amlStatusTarget.classList.remove('hidden')
      } else {
        self.amlStatusTarget.classList.add('hidden')
      }
      self.handleOtherClientBankAccount(total > self.amlLimitsValue[self.currencyTarget.value]);
    }, 500)
  }

  update() {
    this.updatePerson();
    this.updateItems();
  }

  updateStockProducts() {
    let stock_products_to_buyout = {}
    this.productTargets.forEach((el) => {
      let input = el.querySelector('.amount')
      let product = input.dataset.product
      let orders = JSON.parse(input.dataset.orders)
      let serials = JSON.parse(input.dataset.serials)
      if (input.tagName.toLowerCase() === 'input' && input.type === 'checkbox' && input.checked) {
        stock_products_to_buyout[product] = stock_products_to_buyout[product] || {}
        stock_products_to_buyout[product][orders[0]] = stock_products_to_buyout[product][orders[0]] || []
        stock_products_to_buyout[product][orders[0]].push(serials[0])
      } else if (input.tagName.toLowerCase() === 'select' && input.value > 0) {
        stock_products_to_buyout[product] = stock_products_to_buyout[product] || {}
        for (let i = 0; i < parseInt(input.value); i++) {
          stock_products_to_buyout[product][orders[i]] = stock_products_to_buyout[product][orders[i]] || []
          stock_products_to_buyout[product][orders[i]].push(serials[i])
        }
      }
    })
    this.makeBuyoutInvoiceProducts(stock_products_to_buyout)
  }

  makeBuyoutInvoiceProducts(to_buyout) {
    let items = this.haveItems()
    let actions = {to_add: [], to_remove: [], to_update: []}
    for (let product in to_buyout) {
      if (to_buyout.hasOwnProperty(product)) {
        for (let order in to_buyout[product]) {
          if (to_buyout[product].hasOwnProperty(order)) {

            if (items[product] && items[product][order]) {
              if (items[product][order].amount !== to_buyout[product][order].length) {
                actions.to_update.push({
                  item: items[product][order],
                  product: product,
                  order: order,
                  amount: to_buyout[product][order].length,
                  serials: to_buyout[product][order]
                })
              }
            } else {
              actions.to_add.push({
                product: product,
                order: order,
                amount: to_buyout[product][order].length,
                serials: to_buyout[product][order]
              })
            }
          }
        }
      }
    }
    for (let product in items) {
      if (items.hasOwnProperty(product)) {
        for (let order in items[product]) {
          if (items[product].hasOwnProperty(order)) {
            if (!to_buyout[product] || !to_buyout[product][order]) {
              actions.to_remove.push(items[product][order])
            }
          }
        }
      }
    }

    actions.to_remove.forEach((item) => {
      $(item.element).find('.remove_fields').click()
    })

    actions.to_add.forEach((item) => {
      let itemElement = this.itemTargets.filter((el) => el.querySelector('.product_name_with_id').value === '' && el.querySelector('.destroy_input').value !== '1')[0]
      if(!itemElement) {
        $('.add_fields').click();
      }
      itemElement = this.itemTargets.filter((el) => el.querySelector('.product_name_with_id').value === '' && el.querySelector('.destroy_input').value !== '1')[0]
      this.updateBuyoutProductElement(itemElement, item)
    })

    actions.to_update.forEach((item) => {
      this.updateBuyoutProductElement(item.item.element, item)
    })


  }

  updateBuyoutProductElement(element, item) {
    element.querySelector('.product_name_with_id').value = item.product;
    element.querySelector('.restocking_of_order').value = item.order;
    element.querySelector('.serials').value = item.serials.filter((el) => el !== '').join(', ');
    element.querySelector('.amount').value = item.amount;
    element.dataset['buyoutItemCurrencyValue'] = $(this.currencyTarget).val()
    element.dataset['buyoutItemClientValue'] = (this.clientTarget.value.length > 0 || this.eshopClientTarget.value.length > 0).toString();
    window.fill_buyout_product_price.call(element.querySelector('.product_name_with_id'), new Event('change'), {item: {value: item.product}})
    element.querySelector('.serials').dispatchEvent(new Event('keyup'))
    element.querySelector('.amount').dispatchEvent(new Event('change'))

  }

  haveItems() {
    let result = {}
    this.itemTargets.forEach((el) => {
      let amount = parseInt(el.querySelector('.amount').value);
      let product = el.querySelector('.product_name_with_id').value;
      let order = el.querySelector('.restocking_of_order').value;
      let serials = el.querySelector('.serials').value.split(',').map((el) => el.trim());
      let destroyed = el.querySelector('.destroy_input').value === '1';
      if (!destroyed) {
        result[product] = result[product] || {}
        result[product][order] = result[product][order] || {amount: 0, serials: []}
        result[product][order].amount += parseInt(amount)
        result[product][order].serials = result[product][order].serials.concat(serials)
        result[product][order].element = el
      }
    });
    return result;
  }

  updateClient() {
    this.updateCustomer(this.clientTarget, '/clients/');
  }

  updateEshopClient() {
    this.updateCustomer(this.eshopClientTarget, '/eshop_clients/');
  }

  updateNoContractClient() {
    this.updateCustomer(this.noContractClientTarget, '/no_contract_clients/');
  }

  updateCustomer(target, url) {
    let regexped = target.value.match(/(\d+)\)/);
    let self = this;
    if (regexped) {
      var id = regexped[1];
      $.get(url + id + '.json', function (data) {
        self.fill_buyout_invoice_details(data);
      });
    }
    this.updateData();
  }

  updateData() {
    let self = this;
    self.clientBankAccountsTarget.innerHTML = '<i class="fa fa-spinner fa-spin"></i>';
    self.stockProductsTransfersTarget.innerHTM = ''
    let data = self.personParams()
    if (self.last_options !== data) {
      $.get("/buyout_invoices/buyout_options", data, function (data) {
        self.clientBankAccountsTarget.innerHTML = data.client_bank_accounts;
        self.originalClientWrapperTarget.innerHTML = data.original_client;
        self.stockProductsChooserTarget.innerHTML = data.stock_products_chooser;
        self.stockProductsTransfersTarget.innerHTML = data.stock_products_transfers;
        if (self.hasClientBankAccountTarget) {
          self.selectBankAccount(self.clientBankAccountTarget);
        }
        if(data.needs_aml) {
          self.amlStatusTarget.classList.remove('hidden')
        } else {
          self.amlStatusTarget.classList.add('hidden')
        }

        self.last_options = data;
      });
    }
  }

  products() {
    return this.itemTargets.map((el) => ({
      product_name_with_id: el.querySelector('.product_name_with_id').value,
      amount: el.querySelector('.amount').value,
      price: el.querySelector('.price').value,
      serials: el.querySelector('.serials').value,
      restocking_of_order_id: el.querySelector('.restocking_of_order').value,
      damaged_amount: el.querySelector('.damaged_amount').value,
      original_client: el.querySelector('.original_client').value,
      bought_elsewhere: el.querySelector('.bought_elsewhere').checked ? '1' : '0',
    }))
  }

  personParams() {
    let selected_bank_account = this.selectedBankAccount();
    return {
      client_id: this.idFromSuggest(this.clientTarget.value),
      eshop_client_id: this.idFromSuggest(this.eshopClientTarget.value),
      no_contract_client_id: this.idFromSuggest(this.noContractClientTarget.value),
      buyout_invoice: {
        person_type: this.person_type(),
        buyout_appointment_id: this.buyoutAppointmentIdValue,
        original_client_name_with_id: this.originalClientTarget.value,
        currency: this.currencyTarget.value,
        buyout_invoice_products_attributes: this.products(),
        client_bank_account_id: selected_bank_account ? selected_bank_account.dataset.id : null,
      }
    }
  }

  idFromSuggest(sugest) {
    let regexped = sugest.match(/(\d+)\)/);
    if (regexped) {
      return regexped[1];
    }
  }

  initSuggests() {
    let self = this;
    client_suggest_with_callback('.client_suggest_with_callback', function (event, ui) {
      self.clientTarget.value = ui.item.value;
      self.updatePerson()
    }, '?no_eshop_clients=true');
    no_contract_client_suggest_with_callback('.no_contract_client_suggest_with_callback', function (event, ui) {
      self.noContractClientTarget.value = ui.item.value;
      self.updatePerson()
    }, '');
    eshop_client_suggest_with_callback('.eshop_client_suggest_with_callback', function (event, ui) {
      self.eshopClientTarget.value = ui.item.value;
      self.updatePerson()
    }, '');
    client_suggest_with_callback('.original_client_suggest_with_callback', function (event, ui) {
    }, '?no_eshop_clients=true');
  }

  fill_buyout_invoice_details(client) {
    $('#buyout_invoice_is_vat_payer').prop('checked', client['is_vat_payer']);
    $('#buyout_invoice_account_in_iban_format').prop('checked', client['account_in_iban_format']);
    this.amlStatusTarget.innerHTML = client['client_aml_status']['metals']['html'];
    if (client['buyout_currency']) {
      $('#buyout_invoice_currency').val(client['buyout_currency'])
    }
    let params = ['full_name', 'street', 'zip_code', 'city', 'country', 'identification_number', 'vat_number', 'birth_date', 'account_number', 'iban', 'swift']
    $.each(params, (index, field) => {
      if (client[field]) {
        $("#buyout_invoice_" + field).val(client[field])
      }
    })
  }

  reset_buyout_invoice_details() {
    $('#buyout_invoice_is_vat_payer').prop('checked', false);
    $('#buyout_invoice_account_in_iban_format').prop('checked', false);
    this.amlStatusTarget.innerHTML = '';
    let params = ['full_name', 'street', 'zip_code', 'city', 'country', 'identification_number', 'vat_number', 'birth_date', 'account_number', 'iban', 'swift']
    $.each(params, (index, field) => {
      $("#buyout_invoice_" + field).val('')
    })
  }

  person_type() {
    let element = this.personTypeTargets.filter((el) => el.checked)[0]
    if (element) {
      return element.value
    }
  }

  selectBankAccount(bank_account_or_event) {
    let bank_account;
    if (bank_account_or_event.target) {
      bank_account = bank_account_or_event.target.closest('.card--input')
    } else {
      bank_account = bank_account_or_event;
    }
    this.clientBankAccountTargets.forEach((el) => {
      el.classList.remove('card--selected')
      el.querySelector('input').checked = false
    });

    bank_account.classList.add('card--selected')
    bank_account.querySelector('input').checked = true
    let data = JSON.parse(bank_account.dataset.attributes)
    if(data.id) {
      if (data.in_iban_format) {
        $("#buyout_invoice_account_in_iban_format").prop('checked', true)
        $("#buyout_invoice_iban").val(data.iban)
        $("#buyout_invoice_swift").val(data.swift)
      } else {
        $("#buyout_invoice_account_in_iban_format").prop('checked', false)
        $("#buyout_invoice_account_number").val(data.full_number)
      }
      this.clientBankAccountFieldsTarget.classList.add('hidden')
    } else {
      this.clientBankAccountFieldsTarget.classList.remove('hidden')
    }
    return false;
  }

  toggleStockProductsChooser(e) {
    e.preventDefault();
    this.stockProductsTarget.classList.toggle('hidden');
  }

  handleItemOrigianlClient(show_original_client) {
    if (show_original_client) {
      this.buyoutInvoiceProductOriginalClientTargets.forEach((el) => {
        el.parentElement.classList.remove('hidden')
        el.disabled = false
      })
    } else {
      this.buyoutInvoiceProductOriginalClientTargets.forEach((el) => {
        el.parentElement.classList.add('hidden')
        el.disabled = true
      })
    }
  }

  selectedBankAccount() {
    return this.clientBankAccountTargets.filter((el) => el.classList.contains('card--selected'))[0]
  }

  handleOtherClientBankAccount(over_aml_limit) {

    let selected_account = this.selectedBankAccount();
    if(selected_account && !selected_account.dataset.id ) {
      // select first account
      this.selectBankAccount(this.clientBankAccountTargets.filter((el) => !el.classList.contains('card--selected'))[0])
    }
    if (over_aml_limit) {
      this.clientBankAccountTargets.filter((el) => !el.dataset.id).forEach((el) => {el.classList.add('hidden')})
    } else {
      this.clientBankAccountTargets.filter((el) => !el.dataset.id).forEach((el) => {el.classList.remove('hidden')})
    }
  }
}
