import {Controller} from "stimulus"

/**
 * AresController is a Stimulus controller that is used to fetch data from ARES and VIES services.
 * It is used in the client application form to fill in the company details based on the VAT number.
 * It publishes two events: aresFilled and viesFilled.
 * usage
 *   action: 'ares:aresFilled->client-application#fillAres'
 *   action: 'ares:viesFilled->client-application#fillVies'
 */
export default class extends Controller {
  static targets = [
    'dic',
    'ico',
    'vatPayer',
    'fillAres',
    'aresBusinessName',
    'aresStreet',
    'aresCity',
    'aresZipCode',
    'aresVatPayer',
    'aresDic',
    'fillVies',
    'viesBusinessName',
    'viesStreet',
    'viesCity',
    'viesZipCode',
    'vatPayerType',
    'vatPayerTypeNotice',
    'registeredAt',
    'searchAres'
  ];

  connect() {
    if(this.hasIcoTarget) {
      this.getAres();
      this.addAresSearch();
      this.addViesSearch();
      this.update_vat_payer_type_notice();
    }
    this.aresData = {};
    this.viesData = {};
  }

  getAres(e) {
    let identification_number = this.icoTarget.value.trim();
    let country = $('.country').val();
    if (!country || country == '') {
      country = 'cz'
    }
    let target = this.fillAresTarget;
    let self = this;

    if (identification_number && identification_number.length == 8) {
      target.innerHTML = '<i class="fa fa-spinner fa-spin"></i>'

      $.ajax({
        type: 'GET',
        url: '/ares/' + identification_number + '?country=' + country,
        dataType: 'json',
        success: function (data) {
          let result = ''
          if (data.valid) {
            self.aresData = data;
            result += '<div class="ares_add padding5"> <div class="note margin-bottom5">ARES</div>'
            result += `<i class="fa fa-check green"></i> `;
            result += `<span data-ares-target="aresBusinessName">${data.business_name}</span>,
                       <span data-ares-target="aresStreet">${data.street}</span>,
                       <span data-ares-target="aresCity">${data.city}</span>,
                       <span data-ares-target="aresZipCode">${data.zip_code}</span>`;
            if (self.hasDicTarget) {
              result += `<b data-ares-target="aresVatPayer">${data.vat_payer ? 'Plátce DPH' : ''}</b>\n<span data-ares-target="aresDic">${data.dic}</span>`;
            }
            if ($(`#${self.fieldsPrefix()}street`).size() > 0 || $(`#${self.fieldsPrefix()}company`).size() > 0) {
              result += `\n<a href="#" class="btn small" data-action="click->ares#fillAres">Doplnit z ARES</a>`;
            }

            if (data.vat_payer) {
              if (self.hasVatPayerTypeTarget && self.vatPayerTypeTarget.dataset.admin !== '1') {
                self.disableNonVatPayer();
              }
            } else {
              self.enableNonVatPayer();
            }
            if (data.registered_at && self.hasRegisteredAtTarget) {
              self.registeredAtTarget.value = data.registered_at;
            }
          } else if (data.blank) {
            self.aresData = {};
          } else {
            self.aresData = {};
            self.enableNonVatPayer();
            result += '<div class="ares_add padding5"> <div class="note">ARES</div>'
            result += `<i class="fa fa-times red"></i> ${data.message}`;
          }
          result += `</div>`;
          target.innerHTML = result;
        },
        error: function (e) {
          console.log(e)
        }
      });
    } else {
      this.enableNonVatPayer();
      target.innerHTML = '';
    }
    if (e) {
      e.preventDefault();
    }
  }

  disableNonVatPayer(disable_identified_person) {
    if (!this.hasVatPayerTypeTarget) return;

    this.vatPayerTypeTarget.querySelectorAll('option').forEach(option => {
      if (option.value.length == 0) {
        option.disabled = true;
      }
      if (option.value == 'vat_payer') {
        option.selected = true;
      }
      if (disable_identified_person && option.value == 'identified_person') {
        option.disabled = true;
      }
    });
    $(this.vatPayerTypeTarget).trigger('change');
  }

  enableNonVatPayer() {
    if (!this.hasVatPayerTypeTarget) return;

    this.vatPayerTypeTarget.querySelectorAll('option').forEach(option => {
      if (option.value.length == 0) {
        option.disabled = false;
        option.selected = true;
      }
      if (option.value == 'identified_person') {
        option.disabled = false;
      }
    });
    $(this.vatPayerTypeTarget).trigger('change');
  }

  getVies(e) {
    let vat_number = this.dicTarget.value;
    let target = this.fillViesTarget;
    let self = this;
    if (vat_number) {
      target.innerHTML = '<i class="fa fa-spinner fa-spin"></i>'
      $.ajax({
        type: 'GET',
        url: '/vies/' + vat_number,
        dataType: 'json',
        success: function (data) {
          let result = `<div class="ares_add padding5"><div class="note">VIES</div>`;
          if (data.valid) {
            self.viesData = data;
            result += `<i class="fa fa-check green"></i> ${self.dicTarget.dataset.found}: `
            if (self.viesBusinessNameTargets.length == 0) {
              result += `<span data-ares-target="viesBusinessName">${data.business_name}</span>,
                     <span data-ares-target="viesStreet">${data.street}</span>
                     <span data-ares-target="viesCity">${data.city}</span>
                     <span data-ares-target="viesZipCode">${data.zip_code}</span>
                     <a href="#" class="btn small" data-action="click->ares#fillVies">${self.dicTarget.dataset.fill}</a>`;
            }
            if (data.error) {
              self.viesData = {};
              console.log(data.error)
            }
            self.disableNonVatPayer(vat_number.toUpperCase().indexOf('CZ') === -1)
          } else {
            self.viesData = data;
            result += `<i class="fa fa-times red"></i> ${data.message ? data.message : 'Nenalezeno'}`
            if (!data.error) {
              // $(self.vatPayerTypeTarget.checked).val('')
            }
            self.enableNonVatPayer();
          }
          result += `</div>`;
          target.innerHTML = result;
        },
        error: function (e) {
          console.log(e)
        }
      });
    } else {
      target.innerHTML = ''
    }
    if (e) {
      e.preventDefault();
    }
  }


  fillAres(e) {
    let prepend = this.fieldsPrefix()
    if (prepend == 'supplier_') {
      $(`#${prepend}name`).val(this.aresBusinessNameTarget.innerText)
    } else {
      $(`#${prepend}company`).val(this.aresBusinessNameTarget.innerText)
    }

    let street_regexp = new RegExp('^(.*[^0-9]+) (([1-9][0-9]*)/)?([1-9][0-9]*[a-cA-C]?)$')
    let parts = this.aresStreetTarget.innerText.match(street_regexp)
    $(`#${prepend}invoice_name`).val(this.aresBusinessNameTarget.innerText)
    $(`#${prepend}name`).val(this.aresBusinessNameTarget.innerText)
    if ($(`#${prepend}descriptive_street_number`).size() > 0) {
      $(`#${prepend}street`).val(parts[1])
      $(`#${prepend}street_without_number`).val(parts[1])
      $(`#${prepend}descriptive_street_number`).val(parts[3])
      $(`#${prepend}reference_street_number`).val(parts[4])
    } else {
      $(`#${prepend}street`).val(this.aresStreetTarget.innerText)
    }
    $(`#${prepend}city`).val(this.aresCityTarget.innerText)
    $(`#${prepend}zip_code`).val(this.aresZipCodeTarget.innerText)
    if (this.hasAresDicTarget) {
      $(`#${prepend}vat_number`).val(this.aresDicTarget.innerText);
    }
    this.dispatch('aresFilled', {detail: { aresData: this.aresData}})

    e.preventDefault();
    return false;
  }

  fieldsPrefix() {
    return this.icoTarget.id.replace('identification_number', '')
  }

  fillVies(e) {
    let prepend = this.dicTarget.id.replace('vat_number', '')
    if (prepend == 'supplier_') {
      $(`#${prepend}name`).val(this.viesBusinessNameTarget.innerText)
    } else {
      $(`#${prepend}company`).val(this.viesBusinessNameTarget.innerText)
    }

    $(`#${prepend}street`).val(this.viesStreetTarget.innerText)
    $(`#${prepend}city`).val(this.viesCityTarget.innerText)
    $(`#${prepend}zip_code`).val(this.viesZipCodeTarget.innerText)
    this.dispatch('viesFilled', {detail: { viesData: this.viesData}})
    e.preventDefault();
    return false;
  }

  addAresSearch(e) {
    if (this.icoTarget.value && this.icoTarget.value.trim().length === 8) {
      if (!this.hasSearchAresTarget) {
        $(this.icoTarget).after('<a href="#" data-action="click->ares#getAres" title="Vyhledat v ARES" class="ico-ares-search" data-ares-target="searchAres"><i class="fa fa-search"></i></a>');
        this.positionSearch(this.icoTarget, $(this.searchAresTarget));
      }
    } else {
      if (this.hasSearchAresTarget) {
        $(this.searchAresTarget).remove();
      }
    }
    if (e) {
      e.preventDefault()
    }
    return false;
  }

  addViesSearch(e) {
    let search_vies = $('#search-vies');
    if (this.hasDicTarget && this.dicTarget.value) {
      if (search_vies.length == 0) {
        $(this.dicTarget).after('<a href="#" id="search-vies" data-action="click->ares#getVies" title="Vyhledat ve VIES" class="dic-vies-search"><i class="fa fa-search"></i></a>');
        this.positionSearch(this.dicTarget, $('#search-vies'));
      }
    } else {
      search_vies.remove();
    }
    if (e) {
      e.preventDefault()
    }
    return false;
  }

  update_vat_payer_type_notice() {
    if (!this.hasVatPayerTypeTarget) return;
    let value = $(this.vatPayerTypeTarget).val();
    $('.vat_payer_type_notice').hide();
    if (value.length > 0) {
      $('.vat_payer_type_notice.' + value).show();
    }
  }

  positionSearch(element, search){
    let position = $(element).position();
    let width = $(element).width();
    search.css({
        top: position.top + 7,
        position: 'absolute',
        left: position.left + width - search.width()
      });
  }
}
