import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["product", 'total', 'shipping', 'shippingMethod', 'shippingPlace', 'choosePlace', 'submit', 'placeSelectWrapper'];

  update_products() {
    let total = 0;
    total += parseFloat(this.shippingTarget.dataset.price);
    this.productTargets.forEach(function (p) {
      if (p.checked) {
        total += parseFloat(p.dataset.price);
      }
    })

    let formatter = new Intl.NumberFormat('cs-CZ', {
      style: 'currency',
      currency: 'CZK',
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    this.totalTarget.innerHTML = formatter.format(total)
  }

  check(e) {
    let methods = this.shippingMethodTargets;
    let checked = methods.filter(method => method.checked)[0]
    if (checked && checked.value == 'zasilkovna>z-points' && !this.shippingPlaceTarget.value) {

      this.submitTarget.disabled = false;
      e.preventDefault();
      e.stopImmediatePropagation();
      this.choosePlaceTarget.click()
      return false;
    }


  }

  shipping_changed() {
    let methods = this.shippingMethodTargets;
    let checked = methods.filter(method => method.checked)[0]
    if (checked && checked.value == 'zasilkovna>z-points') {
      this.placeSelectWrapperTarget.classList.remove('hidden');
    } else {
      this.placeSelectWrapperTarget.classList.add('hidden');
    }
  }
}
