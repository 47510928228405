import {BaseController} from "./base.js";

/*
    This controller is used to toggle the visibility of a target element.

    Example usage:

    Minimum is to set controller and target:
      = link_to t('crypto.show_contract_saved_detail'), "#", data: { controller: 'toggle', action: 'click->toggle#toggle', toggle_target_element_id_value: "contract_saved_detail_#{presenter.contract_id}") }

    Complex example:
      = link_to t('crypto.show_contract_saved_detail'), "#", data: { controller: 'toggle', 'toggle-target': 'toggleButton', action: 'click->toggle#toggle', 'toggle-status-value': false, 'toggle-target-element-id-value': "contract_saved_detail_#{presenter.contract_id}", 'toggle-label-on-value': t('crypto.hide_contract_saved_detail'), 'toggle-label-off-value': t('crypto.show_contract_saved_detail') }, class: 'yellow'

      - toggle_target_element_id_value - what will be toggled
      - action - set the action to toggle
      - toggle-status-value - initial value of the toggle, if not set, then it is quessed from the target element if it contains the class 'hidden-forced'
      - toggle-label-on-value - label when the target is visible
      - toggle-label-off-value - label when the target is hidden
    You can set targets that will be toggled on and off: based on status.
 */
export default class extends BaseController {
  static targets = ['toggleButton', 'toggleOn', 'toggleOff', 'toggleCheckbox'];
  static values = {
    status: Boolean,
    labelOn: String,
    labelOff: String,
    targetElementId: String
  };

  connect() {
    if(!this.hasStatusValue) {
      if(this.hasToggleCheckboxTarget) {
        this.statusValue = this.toggleCheckboxTarget.checked;
      } else if(this.toggableTarget) {
        this.statusValue = !this.toggableTarget.classList.contains('hidden-forced');
      }
    }
    this.statusValueChanged();
  }

  toggle(event) {
    if (event) {
      event.preventDefault();
    }
    if(this.hasToggleCheckboxTarget && event.target == this.toggleCheckboxTarget) {
      this.statusValue = this.toggleCheckboxTarget.checked;
    } else {
      this.statusValue = !this.statusValue;
    }
  }

  get toggableTarget() {
    return document.getElementById(this.targetElementIdValue);
  }

  statusValueChanged() {
    if (this.statusValue === true) {
      if (this.hasToggleButtonTarget) {
        this.toggleButtonTarget.innerHTML = this.labelOnValue;
      }
      if (this.hasToggleCheckboxTarget) {
        this.toggleCheckboxTarget.checked = true;
      }
      if(this.toggableTarget) {
        this.toggableTarget.classList.remove('hidden-forced');
      }
      this.toggleOffTargets.forEach((element) => { element.classList.add('hidden-forced'); });
      this.toggleOnTargets.forEach((element) => { element.classList.remove('hidden-forced'); });
    } else {
      if (this.hasToggleButtonTarget) {
        this.toggleButtonTarget.innerHTML = this.labelOffValue;
      }
      if (this.hasToggleCheckboxTarget) {
        this.toggleCheckboxTarget.checked = false;
      }
      if(this.toggableTarget) {
        this.toggableTarget.classList.add('hidden-forced');
      }
      this.toggleOffTargets.forEach((element) => { element.classList.remove('hidden-forced'); });
      this.toggleOnTargets.forEach((element) => { element.classList.add('hidden-forced'); });
    }
  }
}
