import {BaseController} from "./base.js";

export default class extends BaseController {
    static targets = [
        'choice',
        'input',
    ];
    connect() {
        this.update();
    }

    update() {
        this.inputTargets.forEach((input) => {
            input.setAttribute('disabled', 'disabled');
            input.removeAttribute('required');
        });

        this.choiceTargets.find((choice) => {
            if (choice.checked) {
                const input = choice.nextElementSibling.nextElementSibling;
                input.removeAttribute('disabled');
                input.setAttribute('required', 'required');
            }
        });
    }
}
