import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['shares', 'amount', 'sharesWrapper', 'amountWrapper']

  connect() {
  }

  changeKind(event) {
    if (event.target.value == 'shares') {
      this.amountTarget.value = ''
      this.amountWrapperTarget.classList.add('hidden')
      this.sharesWrapperTarget.classList.remove('hidden')
    } else if (event.target.value == 'amount') {
      this.sharesTarget.value = ''
      this.sharesWrapperTarget.classList.add('hidden')
      this.amountWrapperTarget.classList.remove('hidden')
    } else if (event.target.value == 'all') {
      this.amountTarget.value = ''
      this.sharesTarget.value = ''
      this.sharesWrapperTarget.classList.add('hidden')
      this.amountWrapperTarget.classList.add('hidden')
    }
  }
}
