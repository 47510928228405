import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['total', 'amount', 'currency']
  connect() {
  }

  updateTotal() {
    let total = 0;
    this.amountTargets.forEach((amount) => {
      let price = parseFloat(amount.dataset.price)
      total += price * parseInt(amount.value) || 0;
    })
    this.totalTarget.innerHTML = this.formatter().format(total);
  }

  formatter(){
    return new Intl.NumberFormat('cs-CZ', {
      style: 'currency',
      currency: this.currencyTarget.value,
    });
  }
}
