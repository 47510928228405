import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['account', 'iban', 'swift', 'format'];

  connect() {
    this.update();
  }

  update() {
    const iban = this.formatTarget.checked;

    this.ibanTarget.disabled = !iban;
    this.swiftTarget.disabled = !iban;
    this.accountTarget.disabled = iban;

    this.ibanTarget.required = iban;
    this.swiftTarget.require = iban;
    this.accountTarget.require = !iban;

    if (iban) {
      this.ibanTarget.focus();
      this.accountTarget.closest('li').classList.add('hidden');
      this.swiftTarget.closest('li').classList.remove('hidden');
      this.ibanTarget.closest('li').classList.remove('hidden');
    } else {
      this.accountTarget.focus();
      this.accountTarget.closest('li').classList.remove('hidden');
      this.swiftTarget.closest('li').classList.add('hidden');
      this.ibanTarget.closest('li').classList.add('hidden');
    }

  }
}
