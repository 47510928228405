import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['accountNumber', 'iban', 'vatPayerDate', 'inIbanFormat'];

  toggleAccountFields() {
    if(this.inIbanFormatTarget.checked) {
      this.accountNumberTarget.classList.add('hidden');
      this.ibanTarget.classList.remove('hidden');
    } else {
      this.accountNumberTarget.classList.remove('hidden');
      this.ibanTarget.classList.add('hidden');
    }
  }

  toggleVatPayerDates(event) {
    if (event.target.value === 'vat_payer') {
      this.vatPayerDateTargets.forEach((element) => {
        element.classList.remove('hidden');
      });
    } else {
      this.vatPayerDateTargets.forEach((element) => {
        element.classList.add('hidden');
      });
    }
  }
}
