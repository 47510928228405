import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['item', 'total', 'totalInfo']

  connect() {
  }

  updateTotal() {
    let total = 0
    let showTotalInfo = false
    this.itemTargets.forEach(function(item) {
      let sell_kind_radio = item.querySelector('.sell_kind:checked')
      let sell_kind = null
      if (sell_kind_radio) {
        sell_kind = sell_kind_radio.value
      }
      let amount = parseFloat(AutoNumeric.getAutoNumericElement(item.querySelector('.amount')).get())
      let shares_input = item.querySelector('.shares')
      let shares = parseFloat(AutoNumeric.getAutoNumericElement(shares_input).get())

      if (sell_kind == 'amount' && amount) {
        total += amount
      } else if (sell_kind == 'shares') {
        total += shares * parseFloat(shares_input.dataset['sharePrice'])
        showTotalInfo = true
      } else if (sell_kind == 'all') {
        total += parseInt(shares_input.dataset['maximum']) * parseFloat(shares_input.dataset['sharePrice'])
        showTotalInfo = true
      }
    })
    this.totalTarget.innerText = this.formatter().format(total)
    if (showTotalInfo) {
      this.totalInfoTarget.classList.remove('hidden')
    } else {
      this.totalInfoTarget.classList.add('hidden')
    }
  }

  formatter() {
    return new Intl.NumberFormat('cs-CZ', {
      style: 'currency',
      currency: $('#currency').val()
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
  }
}
