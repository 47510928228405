import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["icon"];
    static values = {
        id: Number,
        type: String,
        endpoint: String
    }

    toggle() {
        fetch(this.endpointValue, {
            method: 'POST',
            body: JSON.stringify({favourite: { favourite_id: this.idValue, favourite_type: this.typeValue }}),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.favourite) {
                    this.iconTarget.classList.remove('fa-regular');
                    this.iconTarget.classList.remove('favourite__icon--inactive');
                    this.iconTarget.classList.add('fa-solid');
                    this.iconTarget.classList.add('favourite__icon--active');
                } else {
                    this.iconTarget.classList.remove('favourite__icon--active');
                    this.iconTarget.classList.remove('fa-solid');
                    this.iconTarget.classList.add('fa-regular');
                    this.iconTarget.classList.add('favourite__icon--inactive');
                }
            })
            .catch(error => {
                console.log(error)
            })
    }
}
