import {Controller} from "stimulus"

export default class extends Controller {
  static targets = [];
  static values = {
    association: String,
    template: String,
    target: String,
    select2Class: String,
    autocompleteSelectors: Array,
    autocompleteUrls: Array,
  };

  get targetElement() {
    return document.querySelector(this.targetValue);
  }

  add_fields(event) {
    event.preventDefault();
    let new_id = new Date().getTime()
    let regexp = new RegExp("new_" + this.associationValue, "g")
    let new_item = $(this.templateValue.replace(regexp, new_id))
    if (!!this.autocompleteSelectorsValue){
      for(let i = 0; i < this.autocompleteSelectorsValue.length; i++) {
        let autocomplete_selector = this.autocompleteSelectorsValue[i]
        let autocomplete_url = this.autocompleteUrlsValue[i]
        $(autocomplete_selector, new_item).autocomplete({
          source: autocomplete_url
        })
      }
    }
    if(this.targetValue !== 'null') {
      $(this.targetValue).append(new_item)
    } else {
      $(event.target).parent().before(new_item)
    }
    if(!!this.select2ClassValue) {
      $('.' + this.select2ClassValue, new_item).select2({
        theme: 'default',
        language: 'cs',
        width: 'resolve',
        dropdownAutoWidth: true,
        matcher: window.mySearch
      });
    }
  }
}
