import {BaseController} from "./base.js";

/*
* Soubor je schválně pojmenován base_form.js, aby se kontrolér automaticky nezaregistroval.
* */
export class BaseFormController extends BaseController {

    /*
     * Override in subclasses to implement form validation logic.
     */
    isValid() {
        return true;
    }

    validate() {
        if (this.isValid()) {
            this.enableSubmitButton();
            return true;
        } else {
            this.disableSubmitButton();
            return false;
        }
    }

    enableSubmitButton() {
        this.submitButtonTarget.disabled = false;
        this.submitButtonTarget.classList.remove('disabled');
    }

    disableSubmitButton() {
        this.submitButtonTarget.disabled = true;
        this.submitButtonTarget.classList.add('disabled');
    }

    getFloatFieldValue(target) {
        let amount = 0;
        if (target.value != '' && target.value != null) {
            amount = parseFloat(this.stripWhitespaces(target.value));
        }
        if (isNaN(amount)) {
            amount = 0;
        }
        return amount;
    }

    getIntFieldValue(target) {
        let amount = 0;
        if (target.value != '' && target.value != null) {
            amount = parseInt(this.stripWhitespaces(target.value));
        }
        if (isNaN(amount)) {
            amount = 0;
        }
        return amount;
    }

    getIntOrNullFieldValue(target) {
        if (target.value != '' && target.value != null) {
            return parseInt(this.stripWhitespaces(target.value));
        }
        return null;
    }

    getListFieldValues(targets) {
        let elems = targets.filter(element => element.checked)
        if (elems.length > 0) {
            return elems.map(element => element.value);
        }
        return [];
    }

    isCheckBoxChecked(targets) {
        let elem = targets.find(element => element.checked);
        return null == elem ? false : true;
    }

    /*
     * Some fields can be rendered as selects, radios or hidden fields. This method deals with the ambiguity and returns
     * the currently selected option element, checked radio element or the hidden field element, so that you may either
     * read the value, or some additional data attributes from the HTML element.
     */
    getHybridFormFieldElement(targets) {
        if (targets.length > 1) {
            return targets.find(element => element.checked);
        } else if (targets.length == 1) {
            if (targets[0].tagName === 'SELECT') {
                return targets[0].selectedOptions[0];
            } else {
                return targets[0];
            }
        }
        else {
            return null;
        }
    }

    /*
     * Some fields can be rendered as selects, radios or hidden fields. This method deals with the ambiguity and returns
     * the value of the currently selected option, checked radio or the hidden field.
     */
    getHybridFormFieldElementValue(targets) {
        let elem = this.getHybridFormFieldElement(targets);
        return null == elem ? null : elem.value;
    }

    isRadioToggleOn(targets) {
        return this.getHybridFormFieldElementValue(targets) === 'true';
    }

    renderChosenCurrency(classSelector, currencyElement) {
        if (document.getElementsByClassName(classSelector).length > 0) {
            let elems= document.getElementsByClassName(classSelector);
            for (let element of elems) {
                element.innerHTML = currencyElement.dataset.symbol;
            }
        }
    }

    formErrorsValueChanged() {
        this.formErrorsTarget.innerHTML = this.formErrorsValue;
    }

    formFieldInError(element) {
        element.classList.add('form-error');
    }

    formFieldValid(element) {
        element.classList.remove('form-error');
    }
}
