import {BaseFormController} from "./base_form.js";

export default class extends BaseFormController {
    static targets = [
        "percentage"
    ];

    update(event) {
        let target = event.target;
        let percentage= this.getIntFieldValue(event.target);
        if (percentage < 0) {
            percentage = 0;
        }
        else if (percentage > 100) {
            percentage = 100;
        }

        this.percentageTargets.forEach(element => {
            let value = this.getIntFieldValue(element);
            if (value < 0) {
                value = 0;
            }
            else if (value > 100) {
                value = 100;
            }
            if (element === target) {
                element.value = value;

            }
            else {
                if (this.percentageTargets.length === 2) {
                    element.value = 100 - percentage;
                    percentage += value;
                }
            }
        });
    }

    quickSplit(event) {
        event.preventDefault();
        event.stopPropagation();

        let ratios = event.currentTarget.dataset['quickSplitRatios'].split('/');
        this.percentageTargets.forEach((element, index) => {
            element.value = ratios[index] || 0;
        });
    }
}
