import {BaseFormController} from "./base_form.js"

export default class extends BaseFormController {
    static targets = [
        'splitSwitch',
        'amountCzk',
        'splitCzk',
        'amountEur',
        'splitEur',
    ];

    static values = {
        contractId: Number
    }

    connect() {
        this.update();
    }

    update() {
        let splitSwitchChecked = this.splitSwitchTarget.checked;

        if (splitSwitchChecked) {
            this.setReadOnly(this.amountCzkTargets, true);
            this.setReadOnly(this.amountEurTargets, true);
            this.setReadOnly(this.splitCzkTargets, false);
            this.setReadOnly(this.splitEurTargets, false);
            this.calculateAndSetSum(this.amountCzkTarget, this.splitCzkTargets);
            this.calculateAndSetSum(this.amountEurTarget, this.splitEurTargets);
        } else {
            this.setReadOnly(this.amountCzkTargets, false);
            this.setReadOnly(this.amountEurTargets, false);
            this.setReadOnly(this.splitCzkTargets, true);
            this.setReadOnly(this.splitEurTargets, true);
            this.calculateAndSetSplits(this.splitCzkTargets, this.amountCzkTarget);
            this.calculateAndSetSplits(this.splitEurTargets, this.amountEurTarget);
        }
        this.dispatch('update', {detail: {contractId: this.contractIdValue}});
    }

    calculateAndSetSum(tgt, elems) {
        tgt.value = elems.reduce((a, b) => a + this.getFloatFieldValue(b), 0);
    }

    calculateAndSetSplits(tgts, elem) {
        let amount = this.getFloatFieldValue(elem);
        if (amount > 0) {
            tgts.forEach(function (tgt) {
                tgt.value = amount * tgt.dataset.percent / 100;
            });
        }
        else {
            tgts.forEach(function (tgt) {
                tgt.value = 0;
            });
        }
    }
}
