import {BaseController} from './base.js';

export default class extends BaseController {

  connect() {
  }

  move(event) {
    let row_index = event.target.dataset.row
    let column_index = event.target.dataset.column
    $('.game-board').hide()
    $('.loading').show()
    let self = this
    this.delayExecution(1000, function() { self.makeMove(row_index, column_index) }, 'satoshiGameMove');
  }

  makeMove(row, column) {
    $.ajax({
      type: 'POST',
      url: '/satoshiho-hra/tah',
      data: {
        row: row,
        column: column
      },
      success: function (data) {
        $('#satoshi_game_field_and_stats').html(data)
      },
      error: function () {
        alert('Chyba')
      }
    });
  }

}
