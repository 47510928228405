import {BaseFormController} from "./base_form.js";

export default class extends BaseFormController {
    static targets = ['toggle'];

    get is_on() {
        return this.isCheckBoxChecked(this.toggleTargets);
    }

    get togglableTarget() {
        return document.getElementById(this.toggleTarget.dataset.toggleTargetId);
    }

    connect() {
        this.update();
    }

    update() {
        if (this.is_on) {
            this.togglableTarget
                .classList
                .remove('hidden');
        }
        else {
            this.togglableTarget
                .classList
                .add('hidden');
        }
    }
}
