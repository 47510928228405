
import {Controller} from 'stimulus'

export default class extends Controller {
  connect() {
    this.toggleVerificationKindDetails();
  }

  toggleVerificationKindDetails() {
    if ($('#verification_kind_payment').is(':checked')) {
      $('#payment_kind_info').show();
      $('#document_kind_info').hide();
      $('#upload_document').hide();
    } else if ($('#verification_kind_document').is(':checked')) {
      $('#payment_kind_info').hide();
      $('#document_kind_info').show();
      $('#upload_document').show();
    } else {
      $('#payment_kind_info').hide();
      $('#document_kind_info').hide();
      $('#upload_document').hide();
    }
  }
}
