import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['weight', 'weights', 'serials', 'amount']

  connect() {

    console.log('connected form SSO');
  }

  update() {
    update_weights();
    update_amount();
  }

  update_weights() {
    let total = 0;
    this.weightsTarget.value.split("\n").forEach((weight) => {
      total += Number(weight.trim().replace(',', '.'));
    });
    this.weightTarget.value = total;
  }

  update_amount() {
    let total = 0;
    this.serialsTarget.value.split("\n").forEach((serial) => {
      if(serial.trim().length > 0) {
        total += 1;
      }
    });
    this.amountTarget.value = total;
  }
}
