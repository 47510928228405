import {BaseController} from "./base.js";

export default class extends BaseController {
    static targets = ['rotatable'];
    static values = { status: Boolean };

    connect() {
        this.statusValueChanged();
    }

    toggle() {
        this.statusValue = !this.statusValue;
    }

    statusValueChanged() {
        if (this.statusValue) {
            this.rotatableTarget.style.transform = 'rotate(90deg)';
        }
        else {
            this.rotatableTarget.style.transform = 'rotate(0deg)';
        }
    }
}
