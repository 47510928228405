import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
  }

  showAllowAnyDealer(e) {
    e.preventDefault();
    $('.show_allow_dealer_section_link').hide();
    $('.allow_dealer_section').show();
  }

  toggleAllowAnyDealer(e) {
    if ($(e.target).is(':checked')) {
      $('.recommender_suggest').autocomplete({
        source: '/dealer_suggests/new?force_all=true'
      });
    } else {
      $('.recommender_suggest').autocomplete({
        source: '/dealer_suggests/new?only_recommenders=true'
      });
    }
  }
}
