import {Controller} from "@hotwired/stimulus";

/*
* Soubor je schválně pojmenován base.js, aby se kontrolér automaticky nezaregistroval.
* */
export class BaseController extends Controller {
  get csrfToken() {
    // CSRF token is not used in tests, return empty string to avoid errors
    let element = document.querySelector('meta[name="csrf-token"]');
    if (element) {
      return element.getAttribute('content');
    }
    else {
      return '';
    }
  }

    clearValue(element) {
        element.value = '';
    }

    selectOption(select_element, value = null) {
        this.unselectOptions(select_element);
        if (value) {
            let el = select_element.querySelector(`option[value="${value}"]`).selected = true;
            if (el) {
                el.selected = true;
            }
        }
        else {
            let el = select_element.querySelector('option:not([disabled]):not([value=""])');
            if (el) {
                el.selected = true;
            }
        }
    }

    unselectOptions(select_element) {
        select_element.querySelectorAll('option')
                      .forEach((option) => {
                          option.selected = false;
                      });
    }

    setReadOnly(targets, how) {
        targets.forEach(function(target){
            target.readOnly = how;
            if (how) {
                target.classList.add('readonly');
            } else {
                target.classList.remove('readonly');
            }

        })
    }

    readonlySelect(select_element_id, how = true) {
        const select_element = document.getElementById(select_element_id);
        select_element.disabled = how;
        if (how) {
            select_element.classList.add('readonly');
        } else {
            select_element.classList.remove('readonly');
        }
    }

    hideElement(element) {
        $(element).hide();
    }

    showElement(element) {
        $(element).show();
    }

    toggleElementVisibilityOn(visible, element) {
        if (visible) {
            this.showElement(element);
        } else {
            this.hideElement(element);
        }
    }

    hideBySelector(selector) {
        document.querySelectorAll(selector)
                .forEach(element => element.classList.add('hidden'));
    }

    showBySelector(selector) {
        document.querySelectorAll(selector)
                .forEach(element => element.classList.remove('hidden'));
    }

    hideAndDisableBySelector(selector) {
        document.querySelectorAll(selector)
                .forEach(element => {
                    element.classList.add('hidden');
                    element.disabled = true;
                });
    }

    showAndEnableBySelector(selector) {
        document.querySelectorAll(selector)
                .forEach(element => {
                    element.classList.remove('hidden');
                    element.disabled = false;
                });
    }

    hideAndDisableWithParentRowByList(elements) {
        elements.forEach(element => element.disabled = true);
        elements.forEach(element => element.closest('li').classList.add('hidden'));
    }

    showAndEnableWithParentRowByList(elements) {
        elements.forEach(element => element.disabled = false);
        elements.forEach(element => element.closest('li').classList.remove('hidden'));
    }

    readonlyBySelector(selector, how = true) {
        document.querySelectorAll(selector)
                .forEach(element => {
                    element.readOnly = how;
                    if (how) {
                        element.classList.add('readonly');
                    } else {
                        element.classList.remove('readonly');
                    }
                });
    }

    readonlyByList(elements, how) {
        elements.forEach(element => {
            element.readOnly = how;
            if (how) {
                element.classList.add('readonly');
            } else {
                element.classList.remove('readonly');
            }
        });
    }

    delayExecution(ms, callback, timer_name = null, clear_timer_callback = null) {
        timer_name = timer_name || 'timer';
        if (this[timer_name]) {
            clearTimeout(this[timer_name]);
            if (clear_timer_callback) {
                clear_timer_callback();
            }
        }
        this[timer_name] = setTimeout(callback, ms);
    }

    capitalize(s) {
        return s && s[0].toUpperCase() + s.slice(1);
    }

    stripWhitespaces(s) {
        return s.replace(/\s/g, '');
    }

    renderLoaderHtml() {
        return '<div class="animate-pulse" style="display: inline-block;"><i class="fas fa-spinner fa-spin"></i></div>';
    }

    setInnerHtmlIfPresent(element, html) {
      if (element) {
        element.innerHTML = html;
      }
    }

    renderLoaderHtmlInto(element) {
        element.innerHTML = this.renderLoaderHtml();
    }

    replaceElementBySelector(selector, html) {
        $(selector).replaceWith($(html).find(selector)[0]);
    }

  /**
   * Fetch JSON data from the server
   * @param {String} url
   * @param {FormData} formData
   * @param {boolean} debug
   * @param {String} method - POST by default
   * @returns {Promise<*>}
   */
    fetchJson(url, formData,  debug = false, method = 'POST') {
        return fetch(url, {
            method: method,
            body: formData,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Accept': 'application/json',
                // 'Content-Type': 'application/www-form-urlencoded',
                'X-CSRF-Token': this.csrfToken
            }
        }).then(response => {
            if (response.ok) {
                if (debug) {
                    console.debug("Fetch JSON " + method + " " + url + ": ", response);
                }
                if (this.hasStimulusFetchErrorTarget) {
                    this.stimulusFetchErrorTarget.classList.add('hidden');
                    this.stimulusFetchErrorTarget.innerHTML = '';
                }
                return response.json();
            } else {
                if (response.status === 400) {
                    return response.json().then((json) => {
                        console.debug('Bad JSON request '+method+' request ' + url + ': ', json);
                        if (this.hasStimulusFetchErrorTarget) {
                            this.stimulusFetchErrorTarget.innerHTML = json.error.message;
                            this.stimulusFetchErrorTarget.classList.remove('hidden');
                        }
                        throw new Error('Bad JSON request '+method+' request ' + url + ': ', { cause: json });
                    });
                }
                else {
                    console.debug('Something went wrong with JSON ' + method + ' request ' + url + ': ', response);
                    throw new Error('Something went wrong with JSON ' + method + ' request', { cause: response });
                }
            }
        });
    }

    fetchHtml(url, formData, debug = false) {
        return fetch(url, {
            method: 'POST',
            body: formData,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': this.csrfToken
            }
        }).then(response => {
            if (response.ok) {
                if (debug) {
                    console.debug("Fetch HTML POST " + url + ": ", response);
                }
                return response.text();
            } else {
                if (response.status === 400) {
                    return response.text().then((text) => {
                        console.debug('Bad HTML POST request ' + url + ': ', text);
                        throw new Error('Bad HTML POST request ' + url + ': ', { cause: text });
                    });
                }
                console.debug('Something went wrong with HTML POST request ' + url + ': ', response);
                throw new Error('Something went wrong with HTML POST request');
            }
        });
    }

    fetchHtmlGet(url, urlParams, debug = false) {
        return fetch(url + '?' + urlParams, {
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': this.csrfToken
            }
        }).then(response => {
            if (response.ok) {
                if (debug) {
                    console.debug("Fetch HTML GET " + url + ": ", response);
                }
                return response.text();
            } else {
                console.debug('Something went wrong with HTML GET request ' + url + ': ', response);
                throw new Error('Something went wrong with HTML GET request');
            }
        });
    }
}
