import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
  }

  toggleWholeOrAsset(e) {
    if ($(e.target).val() === 'whole') {
      $('.move_for_asset').hide().val('')
      $('#for_asset_to_dealer').hide()
      $('#for_asset_to_dealer input').prop('checked', false)
      $('#for_asset_to_hq').hide()
      $('#for_asset_to_hq input').prop('checked', false)
      $('#whole_to_dealer').show()
      $('#whole_to_hq').show()
    } else {
      $('.move_for_asset').show()
      $('#for_asset_to_dealer').show()
      $('#for_asset_to_hq').show()
      $('#whole_to_dealer').hide()
      $('#whole_to_dealer input').prop('checked', false)
      $('#whole_to_hq').hide()
      $('#whole_to_hq input').prop('checked', false)
    }
  }

  requestKindChanged(e) {
    let request_kind = $(e.target).val()
    if (request_kind === 'for_asset_to_hq' || request_kind === 'whole_to_hq') {
      $('.to_dealer').hide();
    } else {
      $('.to_dealer').show();
    }
    if (request_kind === 'whole_to_hq' || request_kind === 'whole_to_dealer') {
      $('#client_dealer_handover_request_move_for_asset').val('')
    }
  }

  changeDealer(e) {
    e.preventDefault()
    $(e.target).hide()
    $('#target_dealer').hide()
    $('#dealer_name').show()
  }
}
