import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['postToClient']
  static values = {
    id: Number,
    reload: String,
  }

  connect() {
    this.interval = null;
  }

  reload(post_to_client) {
    post_to_client.load(this.reloadValue + ` #${post_to_client.attr('id')} > *`)
  }

  close(event) {
    let src = $(event.srcElement);
    let url = src.parents('form').attr('action');
    let confirm_msg = src.data('confirm');
    let confirmed = false;
    let post_to_client = src.parents('.post_to_client')
    let _this = this;
    event.srcElement.disabled = true;
    if (confirm_msg) {
      confirmed = confirm(confirm_msg);
    } else {
      confirmed = true;
    }
    if (confirmed) {
      $.ajax({
        type: 'PATCH',
        url: url,
        dataType: 'json',
        success: function (data) {
          src.parents('form').replaceWith(data.message)
          _this.reload(post_to_client);
        },
        error: function () {
          event.srcElement.disabled = false;
        }
      });
    } else {
      event.srcElement.disabled = false;
    }

    event.preventDefault();
    return false;
  }

  prepare_agency(event) {
    let src = $(event.srcElement);
    let url = this.reloadValue;
    let post_to_client = src.parents('.post_to_client')
    let timeout;
    let _this = this;
    return false;

    event.srcElement.disabled = true;
    timeout = setInterval(function () {
      _this.reload(post_to_client);
    }, 1000);
    $.ajax({
      type: 'POST',
      url: url,
      dataType: 'json',
      success: function (data) {
        src.parents('form').replaceWith(data.message)
        _this.reload(post_to_client);
      },
      error: function () {
      }
    });


    event.preventDefault();
    return false;
  }

  shipped(){
    $(this.postToClientTarget).fadeOut();
  }

  shipError(){
    alert('Nepodařilo se odeslat zásilku!');
  }

  check_background_job() {
    $.ajax({
      url: "/monitored_background_jobs/#{monitored_background_job_id}.json",
      type: 'get',
      data: {},
      success: function (data) {
        if (data['monitored_background_job']['status'] == 'finished') {
          window.location.reload(true)
        } else if (data['monitored_background_job']['text']) {
          $('#monitored_background_job_text').html(data['monitored_background_job']['text']);
        }
        $('#monitored_background_job_progress').attr('value', data['monitored_background_job']['progress']);
      }
    });
  }
}
