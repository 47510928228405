import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['dialog', 'cardId', 'cardTitle']

  change(event) {
    event.preventDefault();
    this.dialogTarget.showModal()
  }

  choose(event){
    event.preventDefault();
    let choosed = $(event.target)
    let id = choosed.data('cardId')
    let title = choosed.parent().find('.card_title').html()
    this.cardIdTarget.value = id
    this.cardTitleTarget.innerHTML = title
    this.dialogTarget.close()
    this.cardIdTarget.dispatchEvent(new Event('change'))
  }

  close(event){
    event.preventDefault();
    this.dialogTarget.close()
  }

}
