import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["serials", "serialscount", "onstockamount", "serialswarning", "amountwarning", "fillSerials"];

  connect() {
    this.serialsOptions = {}
    this.updateSerials();
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  getSerials() {
    let serialsString = this.serialsTarget.value;
    return serialsString.split("\n").map(serial => serial.trim()).filter(serial => serial.length > 0);
  }

  updateAmountWarning(serialsArray) {

  }

  fillSerials(e){
    this.serialsTarget.value = this.fillSerialsTarget.dataset.serials;
    this.updateSerials();
    e.preventDefault();
    return false;
  }

  updateSerials() {
    let serialsArray = this.getSerials();
    let uniqueArray = serialsArray.filter(this.onlyUnique);
    this.serialscountTarget.innerHTML = "<div>Sériových čísel: "+serialsArray.length+"</div>";
    this.onstockamountTarget.value = serialsArray.length;
    if(serialsArray.length !== uniqueArray.length) {
      this.serialscountTarget.innerHTML += "<div class='red'> Sériová čísla obsahují duplicity</div>";
    }
    if(serialsArray.length !== parseInt(this.onstockamountTarget.value)) {
      this.serialswarningTarget.innerHTML += "Počet sériových čísel se liší od množství k naskladnění";
    } else {
      this.serialswarningTarget.innerHTML += "";
    }
  }

  updateAmount() {
    let serialsArray = this.getSerials();
    if(serialsArray.length > 0 && serialsArray.length !== parseInt(this.onstockamountTarget.value)) {
      this.amountwarningTarget.innerHTML = "Počet sériových čísel se liší od množství k naskladnění";
    } else {
      this.amountwarningTarget.innerHTML = ""
    }
  }
}
