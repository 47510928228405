import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['checkbox']

  check_all(e) {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = true
    });
    if(e){
      e.preventDefault();
    }
    return false;
  }

  uncheck_all(e) {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = false
    });
    if(e){
      e.preventDefault();
    }
    return false;
  }
}
