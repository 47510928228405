import {BaseFormController} from "./base_form.js"

export default class extends BaseFormController {
    DELAY_TIMEOUT = 500;

    static targets = [
        'form',
        'fiatCurrency',
        'fiatAmount',
        'cryptoCurrency',
        'submitButton',
        'grantDiscount',
        'discount',
        'grantCommissionReduction',
        'commissionReduction',
        'useVoucher',
        'voucherCode',
        'toc',
        'gdpr',
        'clientId'
    ];

    static values = {
        estimateValuePath: String,
        checkVoucherPath: String,
        voucherCodeValid: Boolean
    };

    connect() {
        this.voucherCodeValidValue = true;
        this.validate();
    }

    get fiatCurrencyOption() {
        return this.getHybridFormFieldElement(this.fiatCurrencyTargets);
    }

    get fiatCurrency() {
        return this.getHybridFormFieldElementValue(this.fiatCurrencyTargets);
    }

    get fiatAmount() {
        return this.getIntFieldValue(this.fiatAmountTarget);
    }

    get cryptoCurrencyOption() {
        return this.getHybridFormFieldElement(this.cryptoCurrencyTargets);
    }

    get cryptoCurrency() {
        return this.getHybridFormFieldElementValue(this.cryptoCurrencyTargets);
    }

    get grantDiscount() {
        return this.isCheckBoxChecked(this.grantDiscountTargets);
    }
    get discount() {
        return this.getFloatFieldValue(this.discountTarget);
    }

    get grantCommissionReduction() {
        return this.isCheckBoxChecked(this.grantCommissionReductionTargets);
    }

    get commissionReduction() {
        return this.getFloatFieldValue(this.commissionReductionTarget);
    }

    get useVoucher() {
        return this.isCheckBoxChecked(this.useVoucherTargets);
    }

    get voucherCode() {
        return this.voucherCodeTarget.value;
    }

    get toc() {
        return this.isCheckBoxChecked(this.tocTargets);
    }

    get gdpr() {
        return this.isCheckBoxChecked(this.gdprTargets);
    }

    get clientId() {
        return this.clientIdTarget.value;
    }

    validAmount() {
        return this.fiatAmount > 0;
    }

    isValid(){
        if (this.cryptoCurrency && this.fiatCurrency && this.validAmount() && this.voucherCodeValidValue && this.toc && this.gdpr) {
            return true;
        }
        return false;
    }

    update() {
        console.debug('CryptoOneOffOrderController update');
        if (this.grantDiscount) {
            this.showAndEnableWithParentRowByList(this.discountTargets);
        }
        else {
            this.hideAndDisableWithParentRowByList(this.discountTargets);
        }
        if (this.grantCommissionReduction) {
            this.showAndEnableWithParentRowByList(this.commissionReductionTargets);
        }
        else {
            this.hideAndDisableWithParentRowByList(this.commissionReductionTargets);
        }
        if (this.useVoucher) {
            this.showAndEnableWithParentRowByList(this.voucherCodeTargets);
        }
        else {
            this.hideAndDisableWithParentRowByList(this.voucherCodeTargets);
            this.voucherCodeValidValue = true;
            this.clearValue(this.voucherCodeTarget);
            document.getElementById('voucher-status').innerHTML = '';
            document.getElementById('voucher-status-wrapper').classList.add('hidden');
        }
        this.validate();
    }

    updateVoucherStatus() {
        this.validate();
        if (this.voucherCode) {
            let self = this;
            this.delayExecution(this.DELAY_TIMEOUT, function() { self.fetchVoucherStatus(self) }, 'timerVoucher');
        }
    }

    updateEstimate() {
        this.validate();
        if (this.cryptoCurrency && this.fiatCurrency && this.validAmount()) {
            let self = this;
            this.delayExecution(this.DELAY_TIMEOUT, function() { self.fetchEstimate(self) }, 'timerEstimate');
        }
    }

    fetchVoucherStatus(controller) {
        let formData = new FormData();
        formData.append('client_id', controller.clientId);
        formData.append('voucher_code', controller.voucherCode);
        formData.append('fiat_currency', controller.fiatCurrency);
        if (controller.fiatAmount) {
            formData.append('fiat_amount', controller.fiatAmount);
        }

        controller.voucherCodeValidValue = false;

        controller.fetchJson(controller.checkVoucherPathValue, formData)
                  .then(json => {
                      console.debug(json);
                      if (json['voucher']['usable']) {
                          document.getElementById('voucher-status').innerHTML = json['voucher']['value_human'];
                          document.getElementById('voucher-status-wrapper').classList.remove('hidden');
                          controller.voucherCodeValidValue = true;
                      }
                      else {
                          document.getElementById('voucher-status').innerHTML = json['voucher']['reasons'].join('. ');
                          document.getElementById('voucher-status-wrapper').classList.remove('hidden');
                          controller.voucherCodeValidValue = false;
                      }
                  })
                  .catch((error) => {
                      console.debug(error);
                      document.getElementById('voucher-status-wrapper').classList.add('hidden');
                      controller.voucherCodeValidValue = false;
                  })
                  .finally(() => {
                      controller.validate();
                  });
    }

    fetchEstimate(controller) {
        let formData = new FormData();
        formData.append('fiat_amount', controller.fiatAmount);
        formData.append('fiat_currency', controller.fiatCurrency);
        formData.append('crypto_currency', controller.cryptoCurrency);

        controller.fetchJson(controller.estimateValuePathValue, formData)
                  .then(json => {
                      document.getElementById('estimated-value').innerHTML = json.amount_human;
                      document.getElementById('estimated-value-wrapper').classList.remove('hidden');
                  }).catch((error) => {
                      document.getElementById('estimated-value-wrapper').classList.add('hidden');
                  })
                  .finally(() => {
                      controller.validate();
                  });
    }
}
