import {BaseController} from "./base";

export default class extends BaseController {
  static targets = ['total', 'product', 'productNotFromGg', 'select2', 'currency', 'productsNeedingHeadquartersError', 'submit', 'ffc',
    'amlNotice', 'personType', 'originalClientWrapper', 'originalClient', 'stockProductsChooser',
    'products', 'form', 'affidavitOfGoodInstruction'
  ];
  static values = {
    productsNeedingHeadquarters: Array,
    headquartersFfc: Number,
    id: Number,
    clientId: Number,
    eshopClientId: Number,
    originalClientId: Number,
    noContractClientId: Number,
  };

  connect() {
    let self = this;
    $(document).on('select2:select', '.product_id', function (event) {
      self.productChanged(event)
    });
    this.update();
    this.updatePerson()
  }

  calculateTotal(items) {
    let total = 0;
    items.forEach((product) => {
      let prices = JSON.parse(product.dataset.buyoutPrice);
      if (prices && prices['czk']) {
        let field = product.dataset.other == '1' ? 'buyout_price_for_other_goods' : 'buyout_price';
        let price = prices[this.currencyTarget.value][field];
        let deleted = product.querySelector('.destroy_input');
        let amount = product.querySelector('.amount');
        let quantity = 0;
        if (amount) {
          if (amount.nodeName === 'SELECT') {
            quantity = Number(amount.value);
          } else if (amount.nodeName === 'INPUT' && amount.type === 'checkbox') {
            if (amount.checked) {
              quantity = Number(amount.value);
            }
          } else if (amount.nodeName === 'INPUT' && amount.type === 'text') {
            if (deleted && deleted.value === '1') {
              quantity = 0;
            } else {
              quantity = Number(amount.value) || 0;
            }
          }
        } else {
          quantity = 0;
        }

        product.querySelector('.buyout_price').innerHTML = this.formatter().format(price * quantity);
        total += price * quantity;
      }
    });
    return total;
  }

  person_type(){
    let value = 'client';
    this.personTypeTargets.forEach((el, i) => {
      if (el.checked) {
        value = el.value;
      }
    });
    return value;
  }

  updatePerson() {
    let value = this.person_type();
    $('.person_type_dependent').hide();
    $('.person_type_' + value).show();
    this.update_data()
  }

  update_data() {
    let self = this;
    let form_data = new FormData(this.formTarget);

    form_data.set('original_client_name_with_id',  this.hasOriginalClientTarget ? this.originalClientTarget.value : '')
    form_data.set('buyout_appointment_id', this.idValue)
    form_data.set('person_type', this.person_type())
    form_data.delete('_method')
    form_data.delete('authenticity_token')
    if (this.hasClientIdValue) { form_data.set('client_id', this.clientIdValue); }
    if (this.hasNoContractClientIdValue) { form_data.set('client_id', this.noContractClientIdValue) }
    if (this.hasEshopClientIdValue) { form_data.set('client_id', this.eshopClientIdValue) }

    this.productsTarget.innerHTML = '<i class="fa fa-spinner fa-spin"></i>'

    this.fetchJson('/buyout_appointments/data', form_data)
      .then(data => {
        self.originalClientWrapperTarget.innerHtml = data.original_client
        self.productsTarget.innerHTML = data.products
        this.update();
      });
  }

  update() {
    let total = this.calculateTotal(this.productTargets);
    total += this.calculateTotal(this.productNotFromGgTargets);
    this.totalTarget.innerHTML = this.formatter().format(total);
    let limit = this.amlNoticeTarget.dataset.limit;
    if (total >= limit) {
      this.affidavitOfGoodInstructionTarget.classList.add('hidden');
      this.amlNoticeTarget.classList.remove('hidden');
    } else {
      this.affidavitOfGoodInstructionTarget.classList.remove('hidden');
      this.amlNoticeTarget.classList.add('hidden');
    }
    this.updateErrors();
  }

  delayUpdate() {
    setTimeout(() => {
      this.update()
    }, 100);
  }

  productChanged(event) {
    let product = event.target.parentElement.parentElement
    let id = event.target.value
    let self = this
    if (id) {
      $.get(`/products/${id}/buyout_prices.json`, function (data) {
        let json = JSON.stringify(data)
        product.dataset.buyoutPrice = json
        self.update()
      })
    }
    this.updateErrors();
  }

  originalClientChanged(event) {
    let product = event.target.parentElement.parentElement
    if (event.target.value.trim() !== '' && person_type() !== 'buyout_trustee') {
      product.dataset.other = '1'
    } else {
      product.dataset.other = '0'
    }
    this.update()
  }


  enableForm() {
    this.submitTarget.disabled = false;
  }

  formatter() {
    return new Intl.NumberFormat('cs-CZ', {
      style: 'currency',
      currency: this.currencyTarget.value,
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
  }

  updateErrors() {
    let in_headquarters = this.ffcTarget.value == this.headquartersFfcValue
    let not_allowed_products = false;
    let products = this.productsNeedingHeadquartersValue;
    this.productNotFromGgTargets.forEach((product) => {
      let product_deleted = product.closest('.card').querySelector('.destroy_input').value == '1';
      if (!product_deleted && products.indexOf(parseInt(product.querySelector('.product_id').value)) !== -1) {
        not_allowed_products = true;
      }
    })

    if (!in_headquarters && not_allowed_products) {
      this.productsNeedingHeadquartersErrorTargets.forEach((e) => e.classList.remove('hidden'));
      this.submitTarget.disabled = true;
    } else {
      this.productsNeedingHeadquartersErrorTargets.forEach((e) => e.classList.add('hidden'));
      this.submitTarget.disabled = false;
    }
  }


  updateCurrency(event) {
    this.update();
  }
}
