import {BaseFormController} from "./base_form.js"

export default class extends BaseFormController {
    static targets = [
        'form',
        'requestedLimitCurrency',
        'requestedMonthlyLimit',
        'requestedYearlyLimit',
        'purchasePurpose',
        'purchasePurposeOther',
        'incomeSource',
        'incomeSourceBusiness',
        'incomeSourceOther',
        'incomeSourceDocuments',
        'submitButton'
    ];

    connect() {
        this.validate();
    }

    get requestedLimitCurrencyOption() {
        return this.getHybridFormFieldElement(this.requestedLimitCurrencyTargets);
    }

    get requestedLimitCurrency() {
        return this.getHybridFormFieldElementValue(this.requestedLimitCurrencyTargets);
    }

    get requestedMonthlyLimit() {
        return this.getIntFieldValue(this.requestedMonthlyLimitTarget);
    }

    get requestedYearlyLimit() {
        return this.getIntFieldValue(this.requestedYearlyLimitTarget);
    }

    get purchasePurposes() {
        return this.getListFieldValues(this.purchasePurposeTargets);
    }

    get incomeSources() {
        return this.getListFieldValues(this.incomeSourceTargets);
    }

    validMonthlyLimit() {
        return this.requestedMonthlyLimit > 0;
    }

    validYearlyLimit() {
        return this.requestedYearlyLimit > 0;
    }

    validPurchasePurpose() {
        let res = true;
        if (this.purchasePurposes.length === 0) {
            res = false;
        } else if (this.purchasePurposes.includes('other') && this.purchasePurposeOtherTarget.value === '') {
            res = false;
        }
        return res;
    }

    validIncomeSource() {
        let res = true;
        if (this.incomeSources.length === 0) {
            res = false;
        } else if (this.incomeSources.includes('business') && this.incomeSourceBusinessTarget.value === '') {
            res = false;
        } else if (this.incomeSources.includes('other') && this.incomeSourceOtherTarget.value === '') {
            res = false;
        }
        return res;
    }

    validIncomeSourceDocuments() {
        let res = true;
        if (this.incomeSourceDocumentsTarget.files === null || this.incomeSourceDocumentsTarget.files.length === 0) {
            res = false;
        }
        return res;
    }

    isValid(){
        if (this.requestedLimitCurrency && this.validMonthlyLimit() && this.validYearlyLimit() && this.validPurchasePurpose() && this.validIncomeSource() && this.validIncomeSourceDocuments()) {
            return true;
        }
        return false;
    }
}
