import { BaseFormController } from "./base_form";

export default class extends BaseFormController {
  DELAY_TIMEOUT = 1000;
  static targets = ['entryFeesTable'];
  static values ={
    type: String
  }

  redrawFeesTable(event) {
    let fee_type = this.getFeeType()
    let currency = this.getCurrency()
    let client_id = $('#client_id').text()
    let target_amount = this.getTargetAmount()
    let controller = this

    $.get('/contracts/target_amounts_table',
      { client_id: client_id, program: 'investment_account', target_amount: target_amount, fee_type: fee_type, currency: currency },
      function (data) {
        controller.entryFeesTableTarget.innerHTML = data
      });
  }

  changeNotPrepaidFeeFields(event) {
    let monthly_payment = $('.monthly_payment')
    let monthly_payment_row = monthly_payment.parents('.form_row')
    let target_amount_label = $('.target_amount_label')
    if (this.getFeeType() === 'not_prepaid_ongoing_fee') {
      monthly_payment_row.hide()
      monthly_payment.val('0')
      target_amount_label.html(target_amount_label.data('not-prepaid-ongoing-fee-value'))
    } else {
      if (monthly_payment.val() === '0') {
        monthly_payment.val('')
      }
      monthly_payment_row.show()
      target_amount_label.html(target_amount_label.data('normal-value'))
    }
  }

  updateDisplayedEntryFee(event) {
    let client_id = $('#client_id').text()
    let contract_id = $('#contract_id').html()
    let currency = this.getCurrency()
    let target_amount = this.getTargetAmount()
    let monthly_payment = this.getMonthlyPayment()
    let first_payment = this.getFirstPayment()
    let ongoing_fee = this.isOngoingFee()
    let not_prepaid_ongoing_fee = this.isPrepaidOngoingFee()
    let payment_split = this.getPaymentSplit()
    let entry_fee_discount = ''
    let promo_code_id = ''
    if (ongoing_fee == '0') {
      entry_fee_discount = $('.entry_fee_discount').val()
      promo_code_id = $('.promo_code').val()
    }
    let is_dip = $('#investment_account_application_is_dip').is(':checked')
    let changed_first_payment = event.target && (event.target.id == 'investment_account_application_first_payment' || event.target.id == 'investment_account_change_application_first_payment')
    let controller = this

    $.get('/contracts/savings_data',
      {
        client_id: client_id,
        contract_id: contract_id,
        program: 'investment_account',
        currency: currency,
        target_amount: target_amount,
        monthly_payment: monthly_payment,
        first_payment: changed_first_payment ? first_payment : 0,
        ongoing_fee: ongoing_fee,
        not_prepaid_ongoing_fee: not_prepaid_ongoing_fee,
        payment_split: payment_split,
        entry_fee_discount: entry_fee_discount,
        promo_code_id: promo_code_id,
        is_dip: is_dip,
        type: this.typeValue,
      },
      function (data) {
        let entry_fee_order_description = data['entry_fee_order_description']
        let monthly_order_entry_fee_description = data['monthly_order_entry_fee_description']
        let entry_fee_prepayment_description = data['entry_fee_prepayment_description']
        let first_payment = parseFloat(data['first_payment'])
        let allow_fki = data['allow_fki']
        let entry_fee_discount_select = data['entry_fee_discount_select']
        let fixed_entry_fee_percentage = data['fixed_entry_fee_percentage']

        controller.setFirstPayment(first_payment)
        controller.setEntryFeeDescriptions(target_amount, entry_fee_order_description, monthly_order_entry_fee_description, entry_fee_prepayment_description)
        controller.togglePaymentSplit(target_amount)
        controller.toggleFki(allow_fki)
        controller.updateEntryFeeDiscountSelect(entry_fee_discount_select)
        controller.setFixedEntryFeePercentage(fixed_entry_fee_percentage)
      });
  }

  hideDiscount(event) {
    if (this.getFeeType() == 'ongoing_fee') {
      $('#additional_settings').hide()
      $('.discount').hide()
      $('.entry_fee_discount').val('')
      $('.promo_code').val('')
    } else {
      $('.discount').show()
      $('#additional_settings').show()
    }
  }

  getCurrency() {
    return $('.currency').val()
  }

  getFeeType() {
    return $('.installments_type:checked').val()
  }

  isOngoingFee() {
    if (this.getFeeType() == 'ongoing_fee') {
      return '1'
    } else {
      return '0'
    }
  }

  isPrepaidOngoingFee() {
    if (this.getFeeType() == 'not_prepaid_ongoing_fee') {
      return '1'
    } else {
      return '0'
    }
  }

  getTargetAmount() {
    return this.getAutonumericValue('target_amount')
  }

  getMonthlyPayment() {
    return this.getAutonumericValue('monthly_payment')
  }

  getFirstPayment() {
    return this.getAutonumericValue('first_payment')
  }

  getAutonumericValue(klass) {
    let elements = $('.' + klass);
    if (elements.length == 0) {
      return 0
    } else {
      let value = AutoNumeric.getAutoNumericElement(elements[0]).get()
      if (value === '') {
        return 0
      } else {
        return parseFloat(value)
      }
    }
  }

  getPaymentSplit() {
    let payment_split = [];
    $('.payment_split_product_percentage_configuration').each(function () {
      let product_id = $(this).find('.payment_split_product').val()
      let percentage_value = $(this).find('.payment_split_percentage').val()
      let percentage = 0
      if (percentage_value != '') {
        percentage = parseFloat(percentage_value)
      }
      payment_split.push([product_id, percentage])
    })
    return payment_split
  }

  setFirstPayment(first_payment) {
    if (first_payment > 0) {
      $('.first_payment').val(first_payment)
    }
  }

  setEntryFeeDescriptions(target_amount, entry_fee_order_description, monthly_order_entry_fee_description, entry_fee_prepayment_description) {
    if (target_amount > 0) {
      $('#fees_description').css('display', 'inline-block')
      $('#entry_fee_order_description').html(entry_fee_order_description)
      $('#monthly_order_entry_fee_description').html(monthly_order_entry_fee_description)
      $('#entry_fee_prepayment_description').html(entry_fee_prepayment_description)
    } else {
      $('#fees_description').hide()
    }
  }

  togglePaymentSplit(target_amount) {
    if (target_amount > 0) {
      $('#payment_split_form').show()
    } else {
      $('#payment_split_form').hide()
    }
  }

  toggleFki(allow_fki) {
    if (allow_fki) {
      $('.payment_split_product_percentage_configuration.fki').show()
    } else {
      $('.payment_split_product_percentage_configuration.fki').hide()
      $('.payment_split_product_percentage_configuration.fki .payment_split_percentage').val('0')
    }
  }

  updateEntryFeeDiscountSelect(entry_fee_discount_select) {
    $('.entry_fee_discount').replaceWith(entry_fee_discount_select)
  }

  setFixedEntryFeePercentage(fixed_entry_fee_percentage) {
    if (fixed_entry_fee_percentage > 0) {
      $('#investment_account_application_fixed_entry_fee_percentage').val(fixed_entry_fee_percentage)
      $('.discount').hide()
      $('.entry_fee_discount').val('')
    } else {
      $('#investment_account_application_fixed_entry_fee_percentage').val('')
      $('.discount').show()
    }
  }

  toggleDipSettings() {
    if ($('.is_dip').is(':checked')) {
      $('#employer_identification_number').show()
      $('#dip_end_date_info').show()
    } else {
      $('#investment_account_application_employer_identification_number').val('')
      $('#employer_identification_number').hide()
      $('#dip_end_date_info').hide()
    }
  }

  showBankAccountNumberFields(e) {
    e.preventDefault()
    $('#client_bank_account').hide()
    $('#investment_account_change_application_client_bank_account_id').val('')
    $('#account_number_fields').show()
    $('#investment_account_application_client_bank_account_id').val('')
  }

  hideBankAccountNumberFields(e) {
    e.preventDefault()
    $('#account_number_fields').hide()
    $('#client_bank_account').show()
    $('#investment_account_application_bank_account_prefix').val('')
    $('#investment_account_application_bank_account_number').val('')
    $('#investment_account_application_bank_code').val('')
    $('#investment_account_application_iban').val('')
    $('#investment_account_application_swift').val('')
  }

  targetAmountChanged(e) {
    let controller = this
    this.delayExecution(this.DELAY_TIMEOUT, function() { controller.redrawFeesTable(e) }, 'timerRedrawFeesTable')
    this.delayExecution(this.DELAY_TIMEOUT, function() { controller.updateDisplayedEntryFee(e) }, 'timerUpdateDisplayedEntryFee')
  }

  monthlyPaymentChanged(e) {
    let controller = this
    this.delayExecution(this.DELAY_TIMEOUT, function() { controller.updateDisplayedEntryFee(e) }, 'timerUpdateDisplayedEntryFee')
  }

  firstPaymentChanged(e) {
    let controller = this
    this.delayExecution(this.DELAY_TIMEOUT, function() { controller.updateDisplayedEntryFee(e) }, 'timerUpdateDisplayedEntryFee')
  }
}
