import {Controller} from 'stimulus'

export default class extends Controller {
  connect() {
    this.toggleOtherIncomeSources();
  }

  toggleOtherIncomeSources() {
    this.ifCheckedShow('#client_aml_application_primary_income_source_employment', '#primary_income_position')
    this.ifCheckedShow('#client_aml_application_primary_income_source_other', '#other_primary_income_source')
    this.ifCheckedShow('#client_aml_application_funds_source_other', '#other_funds_source')
    this.ifCheckedShow('#client_aml_application_trade_purpose_other', '#other_trade_purpose')
    this.ifCheckedShow('#client_aml_application_pep_true', '#pep_income_origin')
    if ($('#client_aml_application_primary_income_source_employment').is(':checked') || $('#client_aml_application_primary_income_source_business').is(':checked')) {
      $('#primary_income_industry').show()
    } else {
      $('#primary_income_industry').hide()
    }
    if ($('#client_aml_application_funds_source_inheritance').is(':checked') || $('#client_aml_application_funds_source_asset_sale').is(':checked') || $('#client_aml_application_funds_source_other').is(':checked')) {
      $('#other_funds_source_amount').show()
    } else {
      $('#other_funds_source_amount').hide()
    }
    if ($('#client_aml_application_tax_residency_other').is(':checked') || $('#client_aml_application_tax_residency_eu').is(':checked')) {
      $('#tax_number').show()
      $('#tax_country').show()
    } else {
      $('#tax_number').hide()
      $('#tax_country').hide()
    }
  }

  toggleCountrySelect() {
    if ($('#client_aml_application_tax_residency_other').is(':checked')) {
      $('#client_aml_application_tax_residency_country').html($('#world_country').html())
      $('#client_aml_application_tax_residency_country').attr('required', true)
    } else if ($('#client_aml_application_tax_residency_eu').is(':checked')) {
      $('#client_aml_application_tax_residency_country').html($('#eu_country').html())
      $('#client_aml_application_tax_residency_country').attr('required', true)
    } else {
      $('#client_aml_application_tax_residency_country').attr('required', false)
    }
  }

  toggleOtherFundsSources() {
    $('.funds_source').each(function(i, obj) {
      let input = $(obj).find('input[type="checkbox"]')
      if (input.is(':checked')) {
        $(obj).show()
      } else {
        second_page.show()
      }
    })
  }

  ifCheckedShow(checked, show) {
    if ($(checked).is(':checked')) {
      $(show).show()
    } else {
      $(show).hide()
    }
  }
}
