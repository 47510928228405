import {BaseFormController} from "./base_form.js"

export default class extends BaseFormController {
    DELAY_TIMEOUT = 200;

    static targets = [
        'otForm',
        'estimatedAmountBought',
        'estimatesSummary',
        'submitButton',
    ];

    static values = {
        usagePreviewPath: String,
        transferPreviewPath: String
    }

    // Voláno z OverchargeTransferSplitController#update pomocí: this.dispatch('update', {detail: {contractId: this.contractIdValue}});
    // https://stimulus.hotwired.dev/reference/controllers#cross-controller-coordination-with-events
    update(payload) {
        let self = this;
        this.delayExecution(this.DELAY_TIMEOUT, function() { self.fetchEstimate(self, payload.detail.contractId) }, 'timerEstimate');
        this.delayExecution(this.DELAY_TIMEOUT, function() { self.fetchSummary(self) }, 'timerSummary');
    }

    fetchSummary(controller) {
        fetch(controller.usagePreviewPathValue, {
            method: 'POST',
            body: new FormData(controller.otFormTarget),
        }).then(response => {
            if (response.ok) {
                return response.text();
            } else {
                console.debug(response);
                throw new Error('Something went wrong');
            }
        }).then(html => {
            controller.estimatesSummaryTarget.innerHTML = html;
        });
    }

    fetchEstimate(controller, forContractId) {
        let elementId = `estimated_amount_bought_${forContractId}`;
        let formData = new FormData(controller.otFormTarget);
        formData.append("for_contract_id", forContractId);

        fetch(controller.transferPreviewPathValue, {
            method: 'POST',
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.text();
            } else {
                console.debug(response);
                throw new Error('Something went wrong');
            }
        }).then(html => {
            document.getElementById(elementId).innerHTML = html;
            document.getElementById(elementId + '_wrapper').classList.remove('hidden');
        }).catch((error) => {
            document.getElementById(elementId).innerHTML = '';
            document.getElementById(elementId + '_wrapper').classList.add('hidden');
        });
    }
}
