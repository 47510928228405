import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['phone', 'result', 'warning']
  static values = {
    vs: String
  }

  connect() {
    super.connect();
    this.update();
  }

  update() {
    let value = this.phoneTarget.value.replaceAll(' ', '').trim()

    let phone = value.substring(value.length - 9, value.length)
    let prefix = value.substring(0, value.length - phone.length)
    let changed_vs =!(this.vsValue == phone || !this.vsValue)
    if(changed_vs) {
      $(this.warningTarget).show();
    } else {
      $(this.warningTarget).hide();
    }
    if(prefix.length > 0 || changed_vs) {
      $(this.resultTarget).html(`<span class="note">VS:</span> <b>${phone}</b>`)
    } else {
      $(this.resultTarget).html('')
    }

  }
}
