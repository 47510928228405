import {BaseFormController} from "./base_form.js";

export default class extends BaseFormController {
    DELAY_TIMEOUT = 1000;

    static targets = [
        'chosen',
        'submitButton'
    ];

    static values = {
        current: String
    };

    connect() {
        this.update();
    }

    get chosenOption() {
        return this.getHybridFormFieldElement(this.chosenTargets)
    }


    get chosen() {
        return this.getHybridFormFieldElementValue(this.chosenTargets)
    }

    update() {
        console.debug('EnableForChangeController update');
        console.debug(this.currentValue);
        console.debug(this.chosen.toString());
        if (this.chosen.toString() != this.currentValue) {
            this.enableSubmitButton();
        }
        else {
            this.disableSubmitButton();
        }
    }
}
