import {Controller} from 'stimulus'

export default class extends Controller {
  static values = {id: Number}
  static targets = ['companyFields', 'fromNoContractClient', 'noContractClientName', 'referralFields', 'shippingAddressFields', 'bankAccountFields', 'addBankAccount', 'dontAddBankAccount']

  connect() {
    this.updateCompanyFields();
    this.toggleFromNoContractClient();
    this.updateReferralFields();
    if (this.hasNoContractClientNameTarget) {
      $(this.noContractClientNameTarget).autocomplete({
        source: '/no_contract_client_suggests/new',
        select: this.updateFromNoContractClient.bind(this)
      });
    }
  }

  updateFromNoContractClient(event, ui) {
    window.fill_from_no_contract(event, ui)
    let no_contract_client_id;
    if (ui.item && (no_contract_client_id = ui.item.value.match(/\((\d+)\)/))) {

      // post to to update from no contract client
      fetch('/client_applications/' + this.idValue + '/update_from_no_contract_client', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        body: JSON.stringify({
          no_contract_client_id: no_contract_client_id[1]
        })
      })
    }
  }

  toggleCompanyFields(event) {
    this.updateCompanyFields();
  }

  toggleReferralFields(event) {
    this.updateReferralFields();
  }

  toggleFromNoContractClient() {
    if (this.hasFromNoContractClientTarget) {
      if (document.querySelector('input[name="client_application[is_from_no_contract_client]"]:checked')) {
        this.fromNoContractClientTarget.classList.remove('hidden')
      } else {
        this.fromNoContractClientTarget.classList.add('hidden')
      }
    }
  }

  updateCompanyFields() {
    if (this.hasCompanyFieldsTarget) {
      let element = document.querySelector('input[name="client_application[is_company]"]:checked')
      if (element && element.value === 'true') {
        this.companyFieldsTarget.classList.remove('hidden')
      } else {
        this.companyFieldsTarget.classList.add('hidden')
      }
    }
  }

  updateReferralFields() {
    if (this.hasReferralFieldsTarget) {
      let element = document.querySelector('input[name="client_application[add_recommendation]"]:checked')
      if (element) {
        this.referralFieldsTarget.classList.remove('hidden')
      } else {
        this.referralFieldsTarget.classList.add('hidden')
      }
    }
  }

  toggleShippingAddressFields(event) {
    let element = event.target
    if (element.checked) {
      this.shippingAddressFieldsTarget.classList.remove('hidden')
    } else {
      this.shippingAddressFieldsTarget.classList.add('hidden')
    }
  }

  addBankAccount(event) {
    event.preventDefault();
    this.bankAccountFieldsTarget.classList.remove('hidden')
    this.addBankAccountTarget.classList.add('hidden')
    this.dontAddBankAccountTarget.classList.remove('hidden')
    return false;
  }

  clearBankAccount(event) {
    event.preventDefault();
    this.bankAccountFieldsTarget.classList.add('hidden')
    this.addBankAccountTarget.classList.remove('hidden')
    this.dontAddBankAccountTarget.classList.add('hidden')

    this.ibanFieldsTarget.querySelectorAll('input').forEach((input) => { input.required = false; input.value = '' })
    this.accountNumberTarget.querySelectorAll('input').forEach((input) => { input.required = false; input.value = '' })
    this.toggleIbanTarget.checked = false;
    return false;
  }

  fillAres(event){
    fetch('/client_applications/' + this.idValue + '/fill_ares', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify({ares_data: event.detail.aresData})
    })
  }

  fillVies(event){
    fetch('/client_applications/' + this.idValue + '/fill_vies', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify({vies_data: event.detail.viesData})
    })
  }
}
