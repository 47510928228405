import {BaseFormController} from "./base_form.js"

export default class extends BaseFormController {
    static targets = [
        'withdrawAll',
        'amount',
        'currency',
        'accountNumber',
        'submitButton',
        'overchargeAmountCzk',
        'overchargeAmountEur'
    ];

    connect() {
        this.update();
    }

    get currencyOption() {
        return this.getHybridFormFieldElement(this.currencyTargets)
    }

    get currency() {
        return this.getHybridFormFieldElementValue(this.currencyTargets)
    }

    get amount() {
        return this.getFloatFieldValue(this.amountTarget);
    }

    get accountNumber() {
        return this.accountNumberTarget.value;
    }

    get withdrawAll() {
        return this.isCheckBoxChecked(this.withdrawAllTargets);
    }

    get overchargeAmountCzk() {
        return parseFloat(this.overchargeAmountCzkTarget.value);
    }

    get overchargeAmountEur() {
        return parseFloat(this.overchargeAmountEurTarget.value);
    }

    get overchargeAmount() {
        if (this.currency == 'czk') {
            return this.overchargeAmountCzk;
        } else if (this.currency == 'eur') {
            return this.overchargeAmountEur;
        }
    }

    validAmount() {
        return this.currency && this.amount > 0 && this.amount <= this.overchargeAmount;
    }

    isValid(){
        if (this.validAmount() && this.currency && this.accountNumber) {
            return true;
        }
        return false;
    }

    update() {
        this.withdrawAllChanged();
        this.currencyChanged();
        this.validate();
    }

    withdrawAllChanged() {
        if (this.withdrawAll) {
            if (this.currency == 'czk') {
                this.amountTarget.value = this.overchargeAmountCzk;
            } else if (this.currency == 'eur') {
                this.amountTarget.value = this.overchargeAmountEur;
            }
            this.amountTarget.readOnly = true
        }
        else {
            this.amountTarget.readOnly = false
        }
    }

    currencyChanged() {
        $('.show-for-currency').hide();
        $('.show-for-currency-'+this.currency).show();
        let currency = this.currency;
        $('.change-text-for-currency').each(function (index) {
            let elem = this;
            $(elem).text($(elem).data(currency));
        });
    }
}
