import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['prepareDate', 'receiveBy', 'prepareBy', 'toFfc', 'delivery', 'postByAgency', 'date', 'receiveIdCardNumber', 'receiveName', 'receivePhone', 'deliveryDate', 'postByAgencyDate', 'shippingDate', 'postData']

  connect(){
    $(this.receiveByTarget).on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
    this.update();
  }

  update(){
    this.updateDelivery();
  }

  updatePostByAgencyDate(){
    this.postByAgencyDateTarget.value = this.deliveryDateTarget.value
  }

  updateDates(){
    this.shippingDateTarget.value = this.prepareDateTarget.value
    this.receiveDateTarget.value = this.prepareDateTarget.value
  }

  updateDelivery(){
    if(this.deliveryTarget.value == 'agency'){
      this.postByAgencyTarget.classList.remove('hidden')
      this.deliveryDateTarget.disabled = true
      this.deliveryDateTarget.closest('li').classList.add('hidden')
      this.postDataTarget.classList.add('hidden')

    } else {
      this.deliveryDateTarget.disabled = false
      this.deliveryDateTarget.closest('li').classList.remove('hidden')
      this.postByAgencyTarget.classList.add('hidden')
      if (this.deliveryTarget.value == 'post') {
        this.postDataTarget.classList.remove('hidden')
      } else {
        this.postDataTarget.classList.add('hidden')
      }
    }
  }

  updateUser(){
    const user_id = this.receiveByTarget.value
    if(user_id){
      const url = this.receiveByTarget.dataset.url
      fetch(`${url}?user_id=${user_id}`)
        .then(response => response.json())
        .then(user => {
          this.receiveIdCardNumberTarget.value = user.id_card_number
          this.receiveNameTarget.value = user.full_name
          this.receivePhoneTarget.value = user.phone
        })
    }
  }

  updateDate(e){
    const target = e.target.closest('.badge');
    this.dateTargets.forEach((date) => {
      date.classList.remove('badge--success')
    })
    target.classList.add('badge--success')
    this.prepareDateTarget.value = target.dataset.date
    $(this.prepareByTarget).val(target.dataset.userId).trigger('change')
    $(this.prepareDateTarget).trigger('change')
    this.updateDates()
    return false;
  }
}
