import {Controller} from 'stimulus'

export default class extends Controller {
  connect() {
      this.togglePermanentAddressFields();
  }

  toggleCompanyFields() {
    if ($('#dealer_agreement_application_legal_kind_3').is(':checked')) {
      $('.company').show()
      $('.person').hide()
    } else {
      $('.person').show()
      $('.company').hide()
    }
  }

  togglePermanentAddressFields() {
      if ($('.permanent_address_different').is(':checked')) {
          $('.permanent_address_fields').show();
      } else {
          $('.permanent_address_fields').hide();
      }
  }
}
