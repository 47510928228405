import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["kind", "report"]

  connect() {
    this.update_report()
  }

  update_report() {
    if (this.hasKindTarget && this.kindTarget.value === 'funds_meeting') {
      this.reportTarget.classList.remove('hidden');
    } else if (this.hasReportTarget) {
      this.reportTarget.classList.add('hidden')
    }
  }
}
