import {Controller} from "stimulus";

export default class extends Controller {
    static values = {
        countdown: Number, // number of seconds to count down
        toggle: String, // comma separated list of selectors to toggle
        disable: String, // comma separated list of selectors to disable
    }

    static targets = ['countdown']

    connect() {
        this.timer = null;
        this.seconds_left = this.countdownValue;

        if (this.finished()) {
            this.stop();
            this.finish();
        } else {
            this.update()
            this.start();
        }
    }

    disconnect() {
        this.stop()
    }

    stop() {
        clearInterval(this.timer)
        this.timer = null;
    }

    update() {
        this.seconds_left = this.seconds_left - 1;
        this.countdownTarget.textContent = this.timeLeft();
    }

    start() {
        if (this.secondsLeft() > 0) {
            this.timer = setInterval(() => {
                if (this.finished()) {
                    this.stop();
                    this.finish();
                } else {
                    this.update()
                }
            }, 1000)
        }
    }

    secondsLeft() {
        return this.seconds_left;
    }

    finished() {
        return this.secondsLeft() <= 0;
    }

    finish() {
        if (this.hasToggleValue) {
            this.toggleValue.split(',').forEach((selector) => {
                document.querySelectorAll(selector).forEach((el) => el.classList.toggle('hidden'))
            });
        }
        if (this.hasDisableValue) {
            this.disableValue.split(',').forEach((selector) => {
                document.querySelectorAll(selector).forEach((el) => el.disabled = true)
            });
        }
    }


    timeLeft() {
        let timeLeft = this.secondsLeft() * 1000

        let days = Math.floor(timeLeft / (1000 * 60 * 60 * 24))
        let hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        let minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))
        let seconds = Math.floor((timeLeft % (1000 * 60)) / 1000)
        let result = []
        if (days > 0) {
            result.push(`${days}d`)
        }
        if (hours > 0) {
            result.push(`${hours}h`)
        }
        if (minutes > 0) {
            result.push(`${minutes}m`)
        }
        if (seconds > 0) {
            result.push(`${seconds}s`)
        }
        return result.join(' ')
    }
}
