import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['secondPage', 'appearanceMatchesIds']

  connect() {
    this.disablePersonalIdentification()
  }

  chooseIdentification() {
    $('#birth_number').show()
    $('#appearance_matches_ids').show()
    let id = $('#id').val()
    let for_metals = $('#client_aml_application_for_metals').is(':checked')
    let for_crypto = $('#client_aml_application_for_crypto').is(':checked')
    let for_funds = $('#client_aml_application_for_funds').is(':checked')
    let remote_identification = $('#client_aml_application_remote_identification_true').size() == 0 || $('#client_aml_application_remote_identification_true').is(':checked')
    if (remote_identification) {
      $('#remote_identification_help').show()
    }
    else {
      $('#remote_identification_help').hide()
    }
    $('#id_documents').html('<div class="text-center"><i class="fa fa-spinner fa-spin"></i></div>')
    $.ajax({
      type: 'PATCH',
      data: {
        client_aml_application: {
          for_metals: for_metals,
          for_crypto: for_crypto,
          for_funds: for_funds,
          remote_identification: remote_identification
        }
      },
      url: '/client_aml_applications/' + id + '/choose_identification',
      success: function (data) {
        $('#id_documents').html(data)
        $('#id_documents').show()
      },
      error: function () {
        alert('Chyba při načítání formuláře')
      }
    });
  }


  toggleBirthNumber() {
    if ($('#client_aml_application_client_without_birth_number').is(':checked')) {
      $('#client_aml_application_birth_number').prop('required', false)
      $('#client_aml_application_birth_number').hide()
    } else {
      $('#client_aml_application_birth_number').prop('required', true)
      $('#client_aml_application_birth_number').show()
    }
  }



  sendClientSms(e) {
    e.preventDefault();
    $('#birth_number_for_link').val($('#client_aml_application_birth_number').val())
    $('#nationality_for_link').val($('#client_aml_application_nationality').val())
    $('#place_of_birth_for_link').val($('#client_aml_application_place_of_birth').val())
    $('#send_client_sms').submit()
  }

  disablePersonalIdentification() {
    if ($('#dealer_for_themselves').val()) {
      if ($('#client_aml_application_for_funds').is(':checked')) {
        $('#client_aml_application_personal_identification').prop('disabled', true)
        $('#client_aml_application_remote_identification_true').prop('checked', true)
        $('#client_aml_application_remote_identification_false').prop('disabled', true)
        $('#dealer_for_themselves_need_remote').show()
        this.chooseIdentification()
      } else {
        $('#client_aml_application_personal_identification').prop('disabled', false)
        $('#client_aml_application_remote_identification_false').prop('disabled', false)
        $('#dealer_for_themselves_need_remote').hide()
      }
    }
  }
}
