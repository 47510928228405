import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['kind', 'handover', 'loomis', 'ffc'];

  connect() {
  }

  updateKind() {
    if ($(this.kindTarget).val() == 'loomis') {
      $(this.handoverTarget).hide()
      $(this.ffcTarget).val('')
    } else {
      $(this.handoverTarget).show()
    }

  }
}
