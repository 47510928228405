import { BaseFormController } from "./base_form";

export default class extends BaseFormController {
  DELAY_TIMEOUT = 1000;
  connect() {
    if ($('#commodity_account_application_target_amount').size() > 0) {
      this.updateDisplayedEntryFee(null)
    }
  }

  redrawFeesTable() {
    let fee_type = this.getFeeType()
    let currency = this.getCurrency()

    $.get('/contracts/target_amounts_table',
      { program: 'commodity_account', fee_type: fee_type, currency: currency },
      function(data) {
        $('#entry_fees_table').html(data)
      });
  }

  updateDisplayedEntryFee(event) {
    let client_id = $('#client_id').text()
    let currency = this.getCurrency()
    let target_amount = this.getTargetAmount()
    let monthly_payment = this.getMonthlyPayment()
    let first_payment = this.getFirstPayment()
    let first_payment_for_whole_product = this.getFirstPaymentForWholeProduct()
    let ongoing_fee = this.isOngoingFee()
    let payment_split = this.getPaymentSplit()
    let no_discount = $('.no_discount input[type=checkbox]').is(':checked') ? '1' : '0'
    let gift_product_id = $('.gift_product_id')?.is(':checked') ? '1' : '0'
    let fixed_promo_code_id = $('.fixed_promo_code_id')?.val()
    let second_saving_discount = $('.second_saving_discount')?.is(':checked') ? '1' : '0'
    let picked_entry_fee_discount = '';
    if (second_saving_discount == '1' && $('.second_saving_discount_picked_entry_fee_discount').size() > 0) {
      picked_entry_fee_discount = $('.second_saving_discount_picked_entry_fee_discount').val()
    } else if ($('.savings_promotion_picked_entry_fee_discount').size() > 0) {
      picked_entry_fee_discount = $('.picked_entry_fee_discount').val()
    } else {
      picked_entry_fee_discount = 0
    }
    let currency_sign = 'Kč'
    if (currency == 'eur') {
      currency_sign = '€'
    } else if (currency == 'usd') {
      currency_sign = '$'
    }
    let changed_first_payment = event && event.target && event.target.id == 'commodity_account_application_first_payment'
    let controller = this

    $.get('/contracts/savings_data',
      {
        client_id: client_id,
        program: 'commodity_account',
        currency: currency,
        target_amount: target_amount,
        monthly_payment: monthly_payment,
        first_payment: changed_first_payment ? first_payment : 0,
        first_payment_for_whole_product: first_payment_for_whole_product,
        ongoing_fee: ongoing_fee,
        payment_split: payment_split,
        no_discount: no_discount,
        fixed_promo_code_id: fixed_promo_code_id,
        second_saving_discount: second_saving_discount,
        picked_entry_fee_discount: picked_entry_fee_discount,
        gift_product_id: gift_product_id,
      },
      function(data) {
        let entry_fee = parseFloat(data['entry_fee'])
        let entry_fee_before_discount = parseFloat(data['entry_fee_before_discount'])
        let entry_fee_order_description = data['entry_fee_order_description']
        let monthly_order_entry_fee_description = data['monthly_order_entry_fee_description']
        let entry_fee_prepayment_description = data['entry_fee_prepayment_description']
        let first_payment = parseFloat(data['first_payment'])
        let second_saving_discount_allowed = data['second_saving_discount_allowed']
        let second_savings_discount_options = data['second_savings_discount_options']
        let show_savings_promotion_picked_entry_fee_discount = data['show_savings_promotion_picked_entry_fee_discount']
        let savings_promotion_discount_options = data['savings_promotion_discount_options']
        let gift_product_id = data['gift_product_id'] || 0
        controller.setFirstPayment(first_payment)
        controller.setEntryFeeDescriptions(target_amount, entry_fee_order_description, monthly_order_entry_fee_description, entry_fee_prepayment_description)
        controller.togglePaymentSplit(target_amount)
        controller.changeCurrencyLabel(currency)
        controller.updateWholeProductsSavedInfo()
        controller.updateSecondSavingsDiscount(second_saving_discount, second_saving_discount_allowed, second_savings_discount_options)
        controller.hideUnusablePromoCodes(currency, currency_sign)
        if (no_discount == '1' || (entry_fee_before_discount != entry_fee && second_saving_discount != '1' && (!show_savings_promotion_picked_entry_fee_discount || picked_entry_fee_discount == '0') && !fixed_promo_code_id)) {
          $('.no_discount').show()
        } else {
          $('.no_discount').hide()
        }

        $('.savings_promotion_picked_entry_fee_discount select').html(savings_promotion_discount_options)
        if (show_savings_promotion_picked_entry_fee_discount) {
          $('.savings_promotion_picked_entry_fee_discount select').removeAttr('disabled')
          $('.savings_promotion_picked_entry_fee_discount').show()
        } else {
          $('.savings_promotion_picked_entry_fee_discount').hide()
        }
        if (entry_fee_before_discount != entry_fee) {
          $('#first_payment_discount_value').html(fnumber(entry_fee_before_discount - entry_fee) + ' ' + currency_sign)
          $('#first_payment_discount').show()
        } else {
          $('#first_payment_discount').hide()
        }
        if (ongoing_fee == '1' || gift_product_id == 0) {
          $('.gift_product_id').prop('checked', false)
          $('.gift_delivery_method').val('')
        } else {
          $('.gift_product_id').prop('checked', true)
        }
      })
  }

  updatePaymentSplit() {
    let client_id = $('#client_id').html()
    let application_id = $('#id').val()
    let currency = this.getCurrency()
    let target_amount = this.getTargetAmount()
    let payment_split = this.getPaymentSplit()
    let controller = this
    $('#refreshing_payment_split').show()
    $.get('/clients/' + client_id + '/commodity_account_applications/' + application_id + '/payment_split',
      {
        target_amount: target_amount,
        currency: currency,
        payment_split: payment_split
      },
      function(data) {
        $('#payment_split').html(data)
        controller.updateWholeProductsSavedInfo()
        controller.updateBonusGramsPurchaseNotice()
        $('#refreshing_payment_split').hide()
      })
  }

  setPaymentSplitPercentages(event) {
    event.preventDefault()
    let percentages = $(event.target).data('percentages').split(':')
    $('.payment_split_percentage').each(function(index, element) {
      $(element).val(percentages[index] || 0)
    })
    this.updateWholeProductsSavedInfo()
  }

  updateWholeProductsSavedInfo() {
    let payment_split = this.getPaymentSplit()
    let monthly_payment = this.getMonthlyPayment()
    if (monthly_payment == '' || monthly_payment == 0) {
      return
    }
    let currency = this.getCurrency()

    $.get('/contracts/whole_products_saved_info',
      {
        monthly_payment: monthly_payment,
        currency: currency,
        payment_split: payment_split,
      },
      function(data) {
        Object.keys(data).forEach(function (product_id) {
          let product_data = data[product_id]
          let price = product_data[0]
          let months = product_data[1]
          let product_option = $('.payment_split_product option:selected[value=' + product_id + ']')
          let months_span = product_option.parents('.payment_split_product_percentage_configuration').find('.months')
          let price_span = product_option.parents('.payment_split_product_percentage_configuration').find('.price')
          if (months_span) {
            months_span.parents('.save_whole_product_info').show()
            if (parseFloat(months) > 0) {
              months_span.html(months)
              price_span.html(price)
            } else {
              months_span.parents('.save_whole_product_info').hide()
            }
          }
        })
      })
  }

  updateSecondSavingsDiscount(second_saving_discount, second_saving_discount_allowed, second_savings_discount_options) {
    $('.second_saving_discount_picked_entry_fee_discount').html(second_savings_discount_options)
    if (second_saving_discount == '1') {
      $('.picked_entry_fee_discount').attr('disabled', 'disabled')
      $('.second_saving_discount_picked_entry_fee_discount').removeAttr('disabled')
    } else {
      $('.picked_entry_fee_discount').removeAttr('disabled')
      $('.second_saving_discount_picked_entry_fee_discount').attr('disabled', 'disabled')
    }
    if (second_saving_discount_allowed) {
      $('.second_saving_discount').removeAttr('disabled')
    } else {
      $('.second_saving_discount').prop('checked', false).attr('disabled', 'disabled')
    }
  }

  hideUnusablePromoCodes(currency, currency_sign) {
    let discount = $('.fixed_entry_fee_discount_field')
    let value = $('.fixed_promo_code_id').val()
    let target_amount = this.getTargetAmount()
    discount.find('option').each(function(index, element) {
      if (parseFloat($(element).data(currency)) <= target_amount || $(element).attr('value') == '') {
        $(element).removeAttr('disabled')
      } else {
        if ($(element).attr('value') == value) {
          $('.fixed_promo_code_id').val('')
        }
        $(element).attr('disabled', true)
      }
      if ($(element).attr('value') != '') {
        $(element).text($(element).data('code') + ' - ' + $(element).data('value-' + currency) + ' ' + currency_sign)
      }
    })
    if (discount.is(':hidden')) {
      discount.show()
    }
  }

  updateBonusGramsPurchaseOptions() {
    let checkboxes = $('.metals_for_purchase_of_bonus_grams')
    let bonus_grams_purchase = $('.bonus_grams_purchase')
    if (bonus_grams_purchase.val() == 'automatic_selected') {
      checkboxes.show()
    } else {
      checkboxes.hide()
    }
    if (bonus_grams_purchase.val() == 'automatic_all' || bonus_grams_purchase.val() == 'automatic_selected') {
      $('.bonus_grams_purchase_notice').show()
    } else {
      $('.bonus_grams_purchase_notice').hide()
    }
  }

 updateBonusGramsPurchaseNotice() {
   let target_amount = this.getTargetAmount()
   let currency = this.getCurrency()
   let payment_split = this.getPaymentSplit()
   let currency_name = $('#commodity_account_application_currency option:selected').text()
   let entry_fee = 0
   if ($('#entry_fee_order_description span').size > 0) {
     let entry_fee = $('#entry_fee_order_description span').html().replace(' ' + currency_name, '')
   }
   $.get('/contracts/bonus_grams_purchase_notice',
     {
      target_amount: target_amount,
      entry_fee: entry_fee,
      currency: currency,
      payment_split: payment_split
    },
    function(data) {
      $('.bonus_grams_purchase_notice').html(data)
    })
  }

  getCurrency() {
    return $('#commodity_account_application_currency').val()
  }

  getFeeType() {
    return $('.installments_type:checked').val()
  }

  isOngoingFee() {
    if (this.getFeeType() == 'ongoing_fee') {
      return '1'
    } else {
      return '0'
    }
  }

  isPrepaidOngoingFee() {
    if (this.getFeeType() == 'not_prepaid_ongoing_fee') {
      return '1'
    } else {
      return '0'
    }
  }

  getTargetAmount() {
    return this.getAutonumericValue('target_amount')
  }

  getMonthlyPayment() {
    return this.getAutonumericValue('monthly_payment')
  }

  getFirstPayment() {
    return this.getAutonumericValue('first_payment')
  }

  getFirstPaymentForWholeProduct() {
    if ($('.first_payment_for_whole_product').is(':checked')) {
      return '1'
    } else {
      return '0'
    }
  }

  getAutonumericValue(klass) {
    let value = AutoNumeric.getAutoNumericElement($('.' + klass)[0]).get()
    if (value == '') {
      return 0
    } else {
      return parseFloat(value)
    }
  }

  getPaymentSplit() {
    let payment_split = [];
    $('.payment_split_product_percentage_configuration').each(function() {
      let product_id = $(this).find('.payment_split_product').val()
      let percentage_value = $(this).find('.payment_split_percentage').val()
      let percentage = 0
      if (percentage_value != '') {
        percentage = parseFloat(percentage_value)
      }
      payment_split.push([product_id, percentage])
    })
    return payment_split
  }

  setFirstPayment(first_payment) {
    if (this.getTargetAmount() > 0 && first_payment > 0) {
      $('.first_payment').val(first_payment)
    }
  }

  setEntryFeeDescriptions(target_amount, entry_fee_order_description, monthly_order_entry_fee_description, entry_fee_prepayment_description) {
    if (target_amount > 0) {
      $('#fees_description').css('display', 'inline-block')
      $('#entry_fee_order_description').html(entry_fee_order_description)
      $('#monthly_order_entry_fee_description').html(monthly_order_entry_fee_description)
      $('#entry_fee_prepayment_description').html(entry_fee_prepayment_description)
    } else {
      $('#fees_description').hide()
    }
  }

  togglePaymentSplit(target_amount) {
    if (target_amount > 0) {
      $('#payment_split_form').show()
    } else {
      $('#payment_split_form').hide()
    }
  }

  changeCurrencyLabel() {
    let currency_value = $('#commodity_account_application_currency option:selected').text()
    $('.currency').html(currency_value)
  }

  targetAmountChanged(e) {
    let controller = this
    this.delayExecution(this.DELAY_TIMEOUT, function() { controller.updatePaymentSplit(e) }, 'timerUpdatePaymentSplit')
    this.delayExecution(this.DELAY_TIMEOUT, function() { controller.redrawFeesTable(e) }, 'timerRedrawFeesTable')
    this.delayExecution(this.DELAY_TIMEOUT, function() { controller.updateDisplayedEntryFee(e) }, 'timerUpdateDisplayedEntryFee')
  }

  monthlyPaymentChanged(e) {
    let controller = this
    this.delayExecution(this.DELAY_TIMEOUT, function() { controller.updateDisplayedEntryFee(e) }, 'timerUpdateDisplayedEntryFee')
  }

  fillDetails(event) {
    event.preventDefault()
    $('.target_amount').val(event.currentTarget.dataset.targetAmount)
    $('.monthly_payment').val(event.currentTarget.dataset.monthlyPayment)
    this.monthlyPaymentChanged(event)
  }
}
