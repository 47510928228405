import {BaseFormController} from "./base_form.js"

export default class extends BaseFormController {
  DELAY_TIMEOUT = 400;

  static values = {
    estimatePath: String,
    contractId: Number,
    overchargeType: String,
    contractProgram: String,
    currency: String
  }

  static targets = ['amount', 'estimates', 'estimatesWrapper']

  connect() {
    console.debug('CardPaymentForm Connected!')
  }

  update(){
    let self = this
    this.estimatesTarget.innerHTML = this.renderLoaderHtml();
    this.delayExecution(this.DELAY_TIMEOUT, function() { self.fetchEstimate(self) }, 'timerEstimate');
  }

  fetchEstimate(controller) {
    if(this.contractProgramValue !== 'crypto') {
      return;
    }

    if(!this.amountTarget.value) {
      this.estimatesTarget.innerHTML = '';
      this.estimatesWrapperTarget.classList.add('hidden');
      return;
    }

    let formData = new FormData();
    formData.append("for_contract_id", this.contractIdValue);
    formData.append(`contracts_${this.currencyValue}[${this.contractIdValue}]`, this.amountTarget.value);

    this.fetchHtml(controller.estimatePathValue, formData).then(html => {
      this.estimatesTarget.innerHTML = html;
      this.estimatesWrapperTarget.classList.remove('hidden');
    }).catch((error) => {
      console.log(error);
      this.estimatesTarget.innerHTML = '';
      this.estimatesWrapperTarget.classList.add('hidden');
    });
  }

  close(event) {
    this.amountTarget.closest('dialog').close();
    event.preventDefault();
  }

}
