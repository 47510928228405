import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["value", "sums"];

    connect() {
        this.formatters = {
            czk: new Intl.NumberFormat('cs-CZ', {style: 'currency', currency: 'czk'}),
            eur: new Intl.NumberFormat('cs-CZ', {style: 'currency', currency: 'eur'}),
            usd: new Intl.NumberFormat('cs-CZ', {style: 'currency', currency: 'usd'})
        };
        this.recalculate()
    }

    recalculate() {
        let sum = {};
        this.valueTargets.forEach(function (p) {
            if (!sum[p.dataset.currency]) {
                sum[p.dataset.currency] = 0;
            }
            if(p.checked) {
                sum[p.dataset.currency] += parseFloat(p.dataset.value);
            }
        })
        let result =  `<div>`
        for (let currency in sum) {
            result += `<div><strong>${this.formatters[currency].format(sum[currency])}</strong></div>`
        }
        result += '</div>'
        this.sumsTarget.innerHTML = result;
    }
}
