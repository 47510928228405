import {BaseController} from './base.js';

export default class extends BaseController {
  static values = {id: Number}
  static targets = ['newDocumentRow', 'number', 'id', 'field', 'existingDocument', 'document', 'reupload']

  connect() {
    this.toggleSecondPage();
    this.loadNumber()
    this.connectedAt = new Date();
    this.updated_interval = setInterval(this.checkUpdated.bind(this), 5000);
  }

  disconnect() {
    if (this.updated_interval) {
      clearInterval(this.updated_interval);
    }
  }

  toggleSecondPage(e) {
    let obj = $(this.documentTarget);
    let second_page = $(obj).find('.second_page')
    if ($(obj).find('.document_kind_id').is(':checked') || $(obj).find('.document_kind_other').is(':checked')) {
      second_page.show()
      $(obj).find('.first_page_label').html($(obj).find('.first_page_label').data('two-page-label'))
    } else {
      second_page.hide()
      $(obj).find('.first_page_label').html($(obj).find('.first_page_label').data('one-page-label'))
    }
  }

  showUploadOnPhoneDialog(e) {
    e.preventDefault();
    $('#upload_on_phone_dialog').dialog('open')
  }

  showAcceptingDocumentsDialog(e) {
    e.preventDefault();
    $('#accepting_document_dialog').dialog('open')
  }

  checkUpdated() {
    let id = this.idValue;
    fetch('/client_aml_applications/' + id + '/updated', {
      method: 'POST',
      signal: this.abortDocumentsControllerSignal,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify({since: this.connectedAt})
    }).then(response => response.json()).then(data => {
      if (data.updated) {
        $('#aml_application_updated_notice').show();
      } else {
        $('#aml_application_updated_notice').hide();
      }
    }).catch(function (error) {
      $('#aml_application_updated_notice').show();
      console.error('Request failed', error);
    });
  }


  loadNumber() {
    let id = this.idValue;
    let number = this.numberTarget.value;
    let self = this;
    this.abortController = new AbortController()
    this.abortControllerSignal = this.abortController.signal;
    if ($('#client_id').val() && number) {
      fetch('/client_aml_applications/' + id + '/load_document', {
        method: 'POST',
        signal: this.abortControllerSignal,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        body: JSON.stringify({
          number: number,
          document_kind: $(this.documentTarget).find('.document_kind:checked').val()
        })
      }).then(response => response.json()).then(data => {
        if (data.id) {
          self.idTarget.value = data.id;
          self.idTarget.disabled = false;
          self.newDocumentRowTargets.forEach((row) => {
            row.classList.add('hidden');
            $(row).find('input, select').attr('disabled', true);
            $(row).find('input[type=text], select').removeAttr('required');
          });
          self.existingDocumentTarget.innerHTML = `<strong>${data.message}</strong> <a href="#" data-action="client-id-document#reuploadDocuments">${data.reupload}</a> <br><br/>` + data.document;

        } else {
          self.resetDocumentFields();
        }

      }).catch(function (error) {
        console.error('Request failed', error);
        self.resetDocumentFields();
      });
    } else {
      self.resetDocumentFields();
    }
  }

  loadNumberDelayed() {
    let self = this;
    this.delayExecution(500, function () {
      self.loadNumber()
    }, 'timerLoadNumber', function () {
      self.abortController.abort()
    });
  }

  reuploadDocuments(e) {
    this.reuploadTargets.forEach((row) => {
      row.classList.remove('hidden');
      $(row).find('input, select').removeAttr('disabled');
    });
    if (e) {
      e.preventDefault();
      e.target.classList.add('hidden');
    }
  }

  resetDocumentFields() {
    this.idTarget.value = this.idTarget.dataset.original || '';
    this.newDocumentRowTargets.forEach((row) => {
      row.classList.remove('hidden');
      $(row).find('input, select').removeAttr('disabled');
      $(row).find('input[type=text], select').attr('required', 'required');
    });
    this.existingDocumentTarget.innerHTML = '';
  }
}
