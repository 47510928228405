import {BaseController} from "./base.js";

export default class extends BaseController {
    static targets = [
        'choice'
    ];
    connect() {
        this.update();
    }

    update(event) {
        this.updateMutex(event);
        this.updateRequired();
    }

    updateMutex(event) {
        let eventTarget = event ? event.target : null;

        if (eventTarget) {
            let checked = eventTarget.checked;
            let mutex = eventTarget.dataset.mutex;

            this.choiceTargets.forEach((choice) => {
                if (mutex === 'true') {
                    if (checked) {
                        if (choice !== eventTarget) {
                            choice.checked = false;
                            choice.setAttribute('disabled', 'disabled');
                        }
                    } else {
                        if (choice !== eventTarget) {
                            choice.removeAttribute('disabled');
                        }
                    }
                }
            });
        }
        // Uvodni inicializace, hledame, jestli nahodou neni vybrana polozka s atributem mutex
        else {
            for (let ch of this.choiceTargets) {
                if (ch.dataset.mutex === 'true' && ch.checked) {
                    this.updateMutex({ target: ch });
                    break;
                }
            }
        }
    }

    updateRequired() {
        // Atribut data-one-required je nastaven na kazde polozce, nebo zadne, takze vezmeme hodnotu z prvni
        let oneRequired = this.choiceTarget.dataset.oneRequired;

        if (oneRequired === 'true') {
            let count = 0;

            this.choiceTargets.forEach((choice) => {
                if (choice.checked) {
                    choice.setAttribute('required', 'required');
                    count += 1;
                }
                else {
                    choice.removeAttribute('required');
                }
            });

            if (count === 0) {
                this.choiceTargets[0].setAttribute('required', 'required');
            }
        }
    }
}
