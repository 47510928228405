import {BaseFormController} from "./base_form.js";

export default class extends BaseFormController {
    DELAY_TIMEOUT = 1000;

    static targets = [
        'currency',
        'targetAmount',
        'startingPaymentOverrideToggle',
        'startingPaymentOverride',
        'paymentFrequency',
        'periodicalPaymentAmount',
        'firstPayment',
        'usesAutomaticTransfers',
        'usesVoucher',
        'voucherCode',
        'discount',
        'discountValue',
        'commissionReduction',
        'commissionReductionValue',
        'noDealerDiscount',
        'wantsMetalDiscount',
        'wantsFixedDiscount',
        'fixedDealerMarginDiscount',
        'entryFeesTable',
        'formSectionVoucherCode',
        'formSectionDiscountValue',
        'formSectionCommissionReductionValue',
        'formSectionFixedDiscount',
        'formSectionMetalDiscount',
        'submitButton',
        'stimulusFetchError'
    ];

    static values = {
        contractId: Number,
        voucherCodeValid: Boolean,
        entryFeesTablePath: String,
        savingsParametersPath: String,
        checkVoucherPath: String,
        fixedDiscountsPath: String,
        metalDiscountsPath: String,
        currency: String,
        targetAmount: Number,
        startingPaymentForTargetAmount: Number,
        fullStartingPaymentForTargetAmount: Number,
        startingPayment: Number,
        startingPaymentOverride: Number,
        minStartingPaymentOverride: Number,
        fixedDiscount: Number,
        metalDiscount: Boolean,
        voucherCode: String
    };

    connect() {
        this.voucherCodeValidValue = true;
        this.update();
    }

    get currencyOption() {
        return this.getHybridFormFieldElement(this.currencyTargets)
    }

    get currency() {
        return this.getHybridFormFieldElementValue(this.currencyTargets)
    }

    get targetAmount() {
        return this.getIntFieldValue(this.targetAmountTarget);
    }

    get startingPaymentOverrideToggle() {
        if (!this.hasStartingPaymentOverrideToggleTarget) {
            return false;
        }
        return this.isCheckBoxChecked(this.startingPaymentOverrideToggleTargets);
    }

    get startingPaymentOverride() {
        if (!this.hasStartingPaymentOverrideTarget) {
            return null;
        }
        return this.getIntOrNullFieldValue(this.startingPaymentOverrideTarget);
    }

    get paymentFrequency() {
        return this.getHybridFormFieldElementValue(this.paymentFrequencyTargets);
    }

    get periodicalPaymentAmount() {
        return this.getIntFieldValue(this.periodicalPaymentAmountTarget);
    }

    get firstPayment() {
        return this.getIntFieldValue(this.firstPaymentTarget);
    }

    get usesAutomaticTransfers() {
        return this.isRadioToggleOn(this.usesAutomaticTransfersTargets);
    }

    get usesVoucher() {
        return this.isCheckBoxChecked(this.usesVoucherTargets);
    }

    get voucherCode() {
        return this.voucherCodeTarget.value;
    }

    get discount() {
        return this.isCheckBoxChecked(this.discountTargets);
    }

    get commissionReduction() {
        return this.isCheckBoxChecked(this.commissionReductionTargets);
    }

    get fixedDiscount() {
        return this.getHybridFormFieldElementValue(this.wantsFixedDiscountTargets);
    }

    get wantsMetalDiscount() {
        return this.isCheckBoxChecked(this.wantsMetalDiscountTargets);
    }

    get fixedDealerMarginDiscount() {
        return this.isCheckBoxChecked(this.fixedDealerMarginDiscountTargets);
    }

    get noDealerDiscount() {
        return this.isCheckBoxChecked(this.noDealerDiscountTargets);
    }

    get dealerDiscount() {
        return !this.noDealerDiscount;
    }

    validTargetAmount() {
        return this.currency && this.targetAmount && this.targetAmount > 0;
    }

    validPeriodicalPaymentAmount() {
        return this.currency && this.periodicalPaymentAmount && this.periodicalPaymentAmount > 0;
    }

    validStartingPaymentOverride() {
        if (!this.hasStartingPaymentOverrideTarget) {
            return true;
        }
        if (this.startingPaymentOverride === null) {
            return true;
        }
        return this.startingPaymentOverride >= this.minStartingPaymentOverrideValue;
    }

    isValid() {
        return this.validTargetAmount()
            && this.validPeriodicalPaymentAmount()
            && this.validStartingPaymentOverride()
            && this.paymentFrequency
            && this.voucherCodeValidValue;
    }

    changeTargetAmount() {
        this.targetAmountValue = this.targetAmount;
    }

    targetAmountValueChanged() {
        let self = this
        if (this.validTargetAmount()) {
            this.showBySelector('#entry-fee-value-wrapper');
            this.showBySelector('#payment_split_form');
        }
        else {
            this.hideBySelector('#entry-fee-value-wrapper');
            this.hideBySelector('#payment_split_form');
        }
        this.delayExecution(this.DELAY_TIMEOUT, function() { self.fetchSavingParameters(self) }, 'timerSavingParameters');
        this.delayExecution(this.DELAY_TIMEOUT, function() { self.fetchVoucherStatus(self) }, 'timerVoucher');
        this.delayExecution(this.DELAY_TIMEOUT, function() { self.fetchFixedDiscounts(self) }, 'timerFixedDiscounts');
    }

    changeCurrency() {
        this.currencyValue = this.currency;
    }

    currencyValueChanged() {
        this.renderChosenCurrency('chosen-currency', this.currencyOption)

        let self = this
        this.delayExecution(this.DELAY_TIMEOUT, function() { self.fetchFeeTable(self) }, 'timerFeeTable');
        this.delayExecution(this.DELAY_TIMEOUT, function() { self.fetchSavingParameters(self) }, 'timerSavingParameters');
        this.delayExecution(this.DELAY_TIMEOUT, function() { self.fetchVoucherStatus(self) }, 'timerVoucher');
        this.delayExecution(this.DELAY_TIMEOUT, function() { self.fetchFixedDiscounts(self) }, 'timerFixedDiscounts');
        this.delayExecution(this.DELAY_TIMEOUT, function() { self.fetchMetalDiscounts(self) }, 'timerMetalDiscounts');
    }

    changeStartingPaymentOverride() {
        this.startingPaymentOverrideValue = this.startingPaymentOverride;
    }

    startingPaymentOverrideValueChanged() {
        if (!this.validStartingPaymentOverride()) {
            this.showBySelector('#starting-payment-override-min-info');
            return;
        }
        this.hideBySelector('#starting-payment-override-min-info');
        this.updateFirstPaymentAmount();

        let self = this
        this.delayExecution(this.DELAY_TIMEOUT, function() { self.fetchSavingParameters(self) }, 'timerSavingParameters');
    }

    changeFixedDiscount() {
        this.fixedDiscountValue = this.fixedDiscount;
    }

    fixedDiscountValueChanged() {
        let self = this
        this.delayExecution(this.DELAY_TIMEOUT, function () { self.fetchSavingParameters(self) }, 'timerSavingParameters');
    }

    changeMetalDiscount() {
        this.metalDiscountValue = this.wantsMetalDiscount;
    }

    metalDiscountValueChanged() {
        let self = this
        this.delayExecution(this.DELAY_TIMEOUT, function () { self.fetchSavingParameters(self) }, 'timerSavingParameters');
    }

    changeVoucherCode() {
        this.voucherCodeValue = this.voucherCode;
    }

    voucherCodeValueChanged() {
        let self = this
        this.delayExecution(this.DELAY_TIMEOUT, function () { self.fetchSavingParameters(self) }, 'timerSavingParameters');
        this.delayExecution(this.DELAY_TIMEOUT, function () { self.fetchVoucherStatus(self) }, 'timerVoucher');
    }

    update(event) {
        this.validate();
        this.toggleElementVisibilityOn(this.usesVoucher, this.formSectionVoucherCodeTarget);
        this.toggleElementVisibilityOn(this.discount, this.formSectionDiscountValueTarget);
        this.toggleElementVisibilityOn(this.commissionReduction, this.formSectionCommissionReductionValueTarget);
        this.updateFirstPaymentAmount(event);
    }

    updateFirstPaymentAmount(event) {
        let amount = 0;
        if (this.hasStartingPaymentOverrideTarget && this.startingPaymentOverrideToggle && this.startingPaymentOverride !== null) {
            amount = this.startingPaymentOverride - this.minStartingPaymentOverrideValue;
        }
        else {
            amount = this.startingPaymentForTargetAmountValue;
        }
        if (!this.usesAutomaticTransfers) {
            amount = amount + this.periodicalPaymentAmount;
        }
        // Overwrite first payment with calculated amount only in case it is empty, or event target is not first payment input
        if (this.firstPaymentTarget.value == '' || !event || event.target != this.firstPaymentTarget) {
            this.firstPaymentTarget.value = amount;
        }
    }

    showStartingPaymentOverride(event) {
        this.showAndEnableWithParentRowByList(this.startingPaymentOverrideTargets);
        this.startingPaymentOverrideToggleTarget.remove();
        event.preventDefault();
    }

    fetchFeeTable(controller) {
        let urlParams = new URLSearchParams({
            program: 'crypto',
            currency: controller.currency,
        });

        controller.renderLoaderHtmlInto(controller.entryFeesTableTarget);
        controller.fetchHtmlGet(controller.entryFeesTablePathValue, urlParams)
                  .then(html => {
                      // console.debug("fetchFeeTable", html);
                      controller.entryFeesTableTarget.innerHTML = html;
                  });
    }

    fetchSavingParameters(controller) {
        if (!controller.validTargetAmount()) {
            controller.validate();
            return
        }

        let formData = new FormData();
        formData.append('currency', controller.currency);
        formData.append('target_amount', controller.targetAmount);
        formData.append('entry_fee_admin_override', controller.startingPaymentOverride);
        formData.append('dealer_discount', controller.dealerDiscount);
        formData.append('wants_fixed_discount',controller.fixedDiscount);
        formData.append('wants_metal_discount', controller.wantsMetalDiscount);
        formData.append('voucher_code', controller.voucherCode);
        if (controller.contractIdValue) {
            formData.append('contract_id', controller.contractIdValue);
        }

        controller.renderLoaderHtmlInto(document.getElementById('entry-fee-value'));
        controller.fetchJson(controller.savingsParametersPathValue, formData)
            .then(json => {
                // console.debug("Fetch saving parameters", json);
                this.setInnerHtmlIfPresent(document.getElementById('entry-fee-value'), json['parameters']['starting_payment_amount_human']);
                this.setInnerHtmlIfPresent(document.getElementById('full-entry-fee-value'), json['parameters']['full_starting_payment_amount_human']);
                this.setInnerHtmlIfPresent(document.getElementById('starting-payment-override-min-value'), json['parameters']['min_fixed_full_entry_fee_human']);
                this.startingPaymentForTargetAmountValue = json['parameters']['starting_payment_amount'];
                this.fullStartingPaymentForTargetAmountValue = json['parameters']['full_starting_payment_amount'];
                this.minStartingPaymentOverrideValue = json['parameters']['min_fixed_full_entry_fee'];
                this.updateFirstPaymentAmount();
            })
            .catch((error) => {

            })
            .finally(() => {
                controller.validate();
            });
    }

    fetchVoucherStatus(controller) {
        if (!controller.validTargetAmount() || !controller.voucherCode) {
            controller.validate();
            return;
        }

        let formData = new FormData();
        formData.append('currency', controller.currency);
        formData.append('target_amount', controller.targetAmount);
        formData.append('code', controller.voucherCode);

        controller.voucherCodeValidValue = false;
        controller.renderLoaderHtmlInto(document.getElementById('voucher-code-value'));
        controller.fetchJson(controller.checkVoucherPathValue, formData)
            .then(json => {
                // console.debug("fetchVoucherStatus", json);
                if (json['voucher']['usable']) {
                    document.getElementById('voucher-code-value').innerHTML = json['voucher']['value_human'];
                    document.getElementById('voucher-code-value').classList.remove('hidden');
                    document.getElementById('voucher-status-success').classList.remove('hidden');
                    document.getElementById('voucher-status-failure').classList.add('hidden');
                    document.getElementById('voucher-code-invalidity-reasons').innerHTML = '';
                    document.getElementById('voucher-code-invalidity-reasons').classList.add('hidden');
                    document.getElementById('voucher-status-wrapper').classList.remove('hidden');
                    controller.voucherCodeValidValue = true;
                }
                else {
                    document.getElementById('voucher-code-value').innerHTML = '';
                    document.getElementById('voucher-code-value').classList.add('hidden');
                    document.getElementById('voucher-status-success').classList.add('hidden');
                    document.getElementById('voucher-status-failure').classList.remove('hidden');
                    document.getElementById('voucher-code-invalidity-reasons').innerHTML = json['voucher']['reasons'].join('. ');
                    document.getElementById('voucher-code-invalidity-reasons').classList.remove('hidden');
                    document.getElementById('voucher-status-wrapper').classList.remove('hidden');
                    controller.voucherCodeValidValue = false;
                }
            })
            .catch((error) => {
                document.getElementById('voucher-code-value').innerHTML = '';
                document.getElementById('voucher-code-value').classList.add('hidden');
                document.getElementById('voucher-status-success').classList.add('hidden');
                document.getElementById('voucher-status-failure').classList.add('hidden');
                document.getElementById('voucher-code-invalidity-reasons').innerHTML = '';
                document.getElementById('voucher-code-invalidity-reasons').classList.add('hidden');
                document.getElementById('voucher-status-wrapper').classList.add('hidden');
                document.getElementById('voucher-status-wrapper').classList.add('hidden');
                controller.voucherCodeValidValue = false;
            })
            .finally(() => {
                controller.validate();
            });
    }

    fetchFixedDiscounts(controller) {
        if (!controller.hasFormSectionFixedDiscountTarget) {
            return;
        }
        if (!controller.validTargetAmount()) {
            return;
        }

        let formData = new FormData();
        formData.append('currency', controller.currency);
        formData.append('target_amount', controller.targetAmount);

        controller.renderLoaderHtmlInto(controller.formSectionFixedDiscountTarget);
        controller.fetchHtml(controller.fixedDiscountsPathValue, formData)
                  .then(html => {
                      // console.debug("fetchFixedDiscounts", html);
                      this.replaceElementBySelector('#form-section-fixed-discount', html)
                  })
                  .finally(() => {
                      controller.validate();
                  });
    }

    fetchMetalDiscounts(controller) {
        if (!controller.hasFormSectionMetalDiscountTarget) {
            return;
        }
        if (!controller.validTargetAmount()) {
            return;
        }

        let formData = new FormData();
        formData.append('currency', controller.currency);

        controller.renderLoaderHtmlInto(controller.formSectionMetalDiscountTarget);
        controller.fetchHtml(controller.metalDiscountsPathValue, formData)
                  .then(html => {
                      //console.debug("fetchMetalDiscounts", html);
                      this.replaceElementBySelector('#form-section-metal-discount', html)
                  })
                  .finally(() => {
                      controller.validate();
                  });
    }

    fillDetails(event) {
        event.preventDefault()
        this.targetAmountTarget.value = event.currentTarget.dataset.targetAmount
        this.periodicalPaymentAmountTarget.value = event.currentTarget.dataset.monthlyPayment
        this.targetAmountValueChanged()
    }

}
