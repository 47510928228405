import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["output", "serials", "serialsOptions", "damagedSerials", "damagedAmount", 'price', 'boughtElsewhere', 'originalClient'];
  static values = {
    product: Object,
    currency: String,
    client: Boolean
  }

  connect() {
    this.serialsOptions = {}
    this.updateSerials();
  }

  updateSerials() {
    let serialsString = this.serialsTarget.value;
    let serialsArray = serialsString.split(',').map(serial => serial.trim()).filter(serial => serial.length > 0);
    let newOptions = {}
    if (!serialsString) {
      this.damagedSerialsTarget.value = '';
    }
    let options = JSON.parse(this.data.get('options')) || {};
    let damagedSerialsArray = this.damagedSerialsTarget.value.split(',').map(serial => serial.trim()).filter(serial => serial.length > 0);
    serialsArray.forEach(function (serial) {
      newOptions[serial] = options[serial] || {
        checked: damagedSerialsArray.indexOf(serial) != -1
      };
    });

    this.data.set('options', JSON.stringify(newOptions));
    this.updateOptions();
  }

  updateChecked(event) {
    let options = JSON.parse(this.data.get('options'));
    let serial = event.target.dataset.serial
    options[serial].charge = 500
    options[serial].checked = event.target.checked
    let checked_serials = Object.entries(options).reduce(function (prev, item) {
      if (item[1].checked) {
        prev.push(item[0]);
      }
      return prev;
    }, []).sort()
    this.damagedSerialsTarget.value = checked_serials.join(', ')
    this.damagedAmountTarget.value = checked_serials.length
    $(this.damagedAmountTarget).trigger('keyup');
    this.data.set('options', JSON.stringify(options));
  }

  updateOptions() {
    let options = JSON.parse(this.data.get('options'));
    this.serialsOptionsTarget.innerHTML = Object.entries(options).sort(function (a, b) {
      return a[0].localeCompare(b[0])
    }).map(function (option) {
      let checked = option[1].checked ? 'checked' : '';
      let serial = option[0];
      return `<span class="bullion" data-buyout-item-serial-serial="${serial}">
        <span class="bullion__serial">${serial}</span>
        <span for="damaged_${serial}" class="bullion__damaged">
        <input type='checkbox' ${checked} value="1" data-target="checkbox" id="damaged_${serial}" data-action="change->buyout-item#updateChecked" data-serial="${serial}"/>        
        poškozený slitek
        </span>                
        </span>`
    }).join("\n")
  }

  updatePrice(event) {
    let field = 'buyout_price'

    if (event && event.target === this.boughtElsewhereTarget && this.boughtElsewhereTarget.checked && this.originalClientTarget.value) {
      // this.originalClientTarget.value = ''
    } else if (event && event.target === this.originalClientTarget && this.originalClientTarget.value) {
      this.boughtElsewhereTarget.checked = false
    }
    if (!this.clientValue || this.boughtElsewhereTarget.checked) {
      field += '_for_other_goods'
    }
    field += '_' + this.currencyValue;
    this.priceTarget.value = this.productValue[field] ? this.productValue[field] : ''
    window.update_total();
  }

  clientValueChanged() {
    this.updatePrice();
  }

  productValueChanged() {
    this.updatePrice();
  }

  currencyValueChanged() {
    this.updatePrice();
  }
}
