import {Controller} from "stimulus"

export default class extends Controller {

  connect() {
      document.querySelectorAll('.calculator-form').forEach((form) => {
          form.addEventListener('submit', (event) => {
              event.preventDefault();

              const iframe = document.getElementById('calculator');
              iframe.contentWindow.postMessage({ type: 'requestData' }, '*');

              const handleMessage = (e) => {
                  if (e.origin !== window.location.origin) {
                      console.error('Invalid origin:', e.origin);
                      return;
                  }

                  if (e.data.type === 'responseData') {
                      const data = e.data.payload;
                      const image = e.data.image;

                      form.querySelector('.calculator-data').value = JSON.stringify(data);
                      form.querySelector('.calculator-image').value = image;

                      window.removeEventListener('message', handleMessage);

                      form.submit();
                  }
              };

              window.addEventListener('message', handleMessage);
          });
      });

      $('.direct_client_suggest').autocomplete({
          source: '/client_suggests/new?only_direct=true',
      });

      $('.no_contract_client_suggest').autocomplete({
          source: '/no_contract_client_suggests/new'
      });
  }


}
