import {Controller} from 'stimulus'

export default class extends Controller {
  connect() {
    this.togglefields();
  }

  togglefields() {
    if ($('#dealer_funds_dealer_detail_attributes_subject_type_tied_agent').is(':checked')) {
      $('.only_tied_agent').show()
    } else {
      $('.only_tied_agent').hide()
    }
  }
}
