import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ['productAmount', 'coinsAmount', 'coinsForm', 'tubeOverview', 'asTubeForm'];
    static values = {
        coinsInProduct: Number,
    };

    show_coins(e) {
        this.coinsFormTarget.classList.remove('hidden');
        this.asTubeFormTarget.classList.add('hidden');
        this.productAmountTarget.value = 0;
        if (this.hasCoinsAmountTarget) {
            this.coinsAmountTarget.focus();
        }
        this.update_amounts(e)
        e.preventDefault();
    }

    show_tubes(e) {
        this.asTubeFormTarget.classList.remove('hidden');
        this.coinsFormTarget.classList.add('hidden');
        this.coinsAmountTarget.value = 0;
        if (this.hasCoinsAmountTarget) {
            this.coinsAmountTarget.focus();
        }
        this.update_amounts(e)
        e.preventDefault();
    }

    update_amounts(e){
        if(!this.hasCoinsAmountTarget) {
            return false;
        }
        let coins = this.coinsAmountTarget.value;
        let amount = this.productAmountTarget.value;

        let productAmount = Math.floor(coins / this.coinsInProductValue);
        let only_coins = coins % this.coinsInProductValue;
        // this.productAmountTarget.value = productAmount;
        let text = [];
        if (productAmount > 0) {
            text.push(productAmount + '&times; tuba');
        }
        if(only_coins > 0 && productAmount > 0) {
            text.push(only_coins + 'ks');
        }
        this.tubeOverviewTarget.innerHTML = text.join(' a ');
    }


}
