import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['toggle', 'expandableContent']

  connect() {
    let toggle_text = $(this.context.element).data('toggle-text');
    if (this.expandableContentTarget.innerText.length > 600 || this.expandableContentTarget.scrollHeight > 100) {
      $(this.expandableContentTarget).after("<a class='expandable__toggle' data-expandable-target='toggle' data-action='click->expandable#toggle' href='#'>"+toggle_text+"</a>")
    } else {
      $(this.expandableContentTarget).addClass('expandable__content--expanded')
    }
  }

  toggle(e){
    $(this.expandableContentTarget).toggleClass('expandable__content--expanded')
    e.preventDefault()
  }
}
