import {BaseController} from "./base";
import Card from 'creditcards/card';
import visa from 'creditcards-types/types/visa';
import mastercard from 'creditcards-types/types/mastercard';
import sha256 from 'crypto-js/sha256';

export default class extends BaseController {
  static targets = ["number", "expiry", "cvc", "submit", "name", 'overlay', 'overlayContent', 'overlayText', 'closeOverlay', 'ownerName', 'iframe'];
  static values = {
    key: String,
    merchantId: String,
    requestId: String,
    authorizeUrl: String,
    verifyThreedsUrl: String,
    updateUrl: String,
    createUrl: String,
    statusUrl: String,
    kind: String,
    clientName: String
  };

  connect() {
    this.update_state = false;
    this.showValidationErrors = false;
  }

  disconnect() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  cardNumber() {
    return this.numberTarget.value.replace(/\s/g, '')
  }

  createPkey(next_step) {
    let month = this.expiryTarget.value.split('/')[0];
    let year = this.expiryTarget.value.split('/')[1];
    let result = JSON.parse(window.keyCreation(this.merchantIdValue, this.requestIdValue, this.keyValue, this.cardNumber(), month, year, this.cvcTarget.value, this.ownerNameValue))
    let self = this;
    if (result && result['z2'] === '0') {
      this.setState('success');
      try {
        this.setState('loading');
        this.updateAuthorization({
          pkey: result['PKey'],
          card_name: this.hasNameTarget ? this.nameTarget.value : '',
          secure_3d_trxid: result['3ds_trxid'],
          device_authorization_url: result['3ds_method'],
          secure_3d_version: result['3ds_version'],
          state: result['3ds_method'] ? 'need_device_fingerprint' : 'need_authorization'
        }).then(response => {
          if (result['3ds_method']) {
            self.device_fingerprint(result['3ds_method'], result['3ds_trxid'], next_step)
          } else {
            next_step.bind(self)();
          }
        })
      } catch (error) {
        this.setState('error', error.message || 'Chyba')
        console.log(error);
      }
    } else {
      this.setState(result.z2 === '0' ? 'success' : 'error', result.z3);
    }

  }

  submit(event) {
    event.preventDefault();
    this.showValidationErrors = true;
    this.submitTarget.disabled = true;
    if (this.validate()) {
      this.setState('loading');
      this.showOverlay();
      this.create().then(response => {
        this.requestIdValue = response.request_id;
        this.createPkey(this.authorize)
      }).catch(error => {
        if (error.cause && error.cause instanceof Response && error.cause.status === 422) {
          error.cause.json().then(data => {
            this.setState('error', data.error)
          })
        } else {
          this.setState('error', 'Chyba při autorizaci karty')
        }
      });
      return false;
    }
  }

  authorize() {
    this.setState('loading', 'Ověření karty')
    this.fetchJson(this.replaceRequestID(this.authorizeUrlValue), new FormData(), false, 'POST').then(response => {
      if (response.state !== 'authorized') {
        this.update_state = true;
        this.interval = setInterval(this.updateStateIfNeeded.bind(this), 5000);
        if (response['need_3ds?']) {
          window.location.href = response.secure_3d_url;
        }
      }
      this.updateState(response);
    }).catch(error => {
      this.setState('error', error.message || 'Chyba - AUTHORIZE')
    })
  }

  validate() {
    let valid = true;
    this.submitTarget.disabled = true;
    valid = this.validateCardNumber() && valid;
    valid = this.validateCVC() && valid
    valid = this.validateExpiry() && valid;
    valid = this.validateSameOwner() && valid;
    this.submitTarget.disabled = !valid;
    return valid;
  }

  updateExpiry() {
    let v = this.expiryTarget.value;
    if (v.length === 3 && !v.includes('/')) {
      this.expiryTarget.value = v[0] + v[1] + '/' + v[2];
    }
  }


  validateExpiry() {
    let errors = [];
    if (!this.expiryTarget.value) {
      errors.push(this.expiryTarget.dataset.required);
    } else if (!this.expiryTarget.value.match(/^\d{2}\/\d{2}$/)) {
      errors.push(this.expiryTarget.dataset.invalid)
    } else {
      let split = this.expiryTarget.value.split('/', 2)
      let year = new Date().getFullYear().toString().slice(-2)
      let month = new Date().getMonth() + 1
      if (split[0] > 12 || split[1] < year || (split[1] === year && parseInt(split[0]) <= month)) {
        errors.push(this.expiryTarget.dataset.invalid)
      }
    }

    if (errors.length > 0) {
      if (this.showValidationErrors) {
        this.error(this.expiryTarget, errors)
      }
      return false
    } else {
      this.success(this.expiryTarget)
      return true;
    }
  }

  validateCVC() {
    if (!this.cvcTarget.value) {
      if (this.showValidationErrors) {
        this.error(this.cvcTarget, [this.cvcTarget.dataset.required])
      }
      return false;
    } else {
      this.success(this.cvcTarget)
      return true;
    }
  }

  validateCardNumber() {
    let errors = [];
    if (!this.cardNumber()) {
      errors.push(this.numberTarget.dataset.required)
    } else if (!this.isValidCardNumber(this.cardNumber())) {
      errors.push(this.numberTarget.dataset.invalid)
    }

    if (errors.length > 0 && this.showValidationErrors) {
      this.error(this.numberTarget, errors)
    } else {
      this.success(this.numberTarget)
    }
    this.numberTarget.value = Card([visa, mastercard]).format(this.cardNumber());
    return errors.length === 0;
  }

  formatCardNumber() {
    const card = Card([visa, mastercard]);
    if (card.isValid(this.cardNumber())) {
      this.numberTarget.value = Card([visa, mastercard]).format(this.cardNumber(), ' ');
    } else {
      // add space every four characters
      this.numberTarget.value = this.cardNumber().replace(/\s/g, '').replace(/(.{4})/g, '$1 ').trim();
    }

  }


  validateSameOwner() {
    if (!this.ownerNameTarget.value) {
      this.error(this.ownerNameTarget, [this.ownerNameTarget.dataset.required])
      return false;
    }
    if (!this.isSameCardOwner()) {
      this.error(this.ownerNameTarget, [this.ownerNameTarget.dataset.mismatch])
      return false;
    } else {
      this.success(this.ownerNameTarget)
      return true;
    }
  }

  reset(target) {
    let item = target.closest('.form_item')
    item.classList.remove('error')
    item.classList.add('success')
    if (item.querySelector('.error_message')) {
      item.querySelector('.error_message').innerHTML = '';
    }
  }

  closeOverlay() {
    this.overlayTarget.classList.add('hidden');
    this.iframe.remove();
    this.iframe = null;
    this.requestIdValue = null;
    return false;
  }

  success(target) {
    this.reset(target)
    target.closest('.form_item').classList.add('success')
  }

  error(target, errors) {
    this.reset(target)
    let item = target.closest('.form_item')
    item.classList.add('error')
    let error_element = item.querySelector('.error_message')
    if (!error_element) {
      error_element = document.createElement('div')
      error_element.classList.add('error_message');
      item.appendChild(error_element)
    }
    error_element.innerHTML = errors.join(', ')
  }

  device_fingerprint(url, trxid, next_step) {
    // Create the iframe
    this.setState('loading', 'Ověření zařízení')
    let data = {
      threeDSMethodNotificationURL: this.replaceRequestID(this.verifyThreedsUrlValue),
      threeDSServerTransID: trxid,
    }
    if (this.iframe) {
      this.iframe.remove();
    }

    this.iframe = document.createElement('iframe');
    this.iframe.style.display = 'none'; // Hide the iframe
    this.iframe.name = 'hidden_iframe'; // Give the iframe a name
    this.iframe.id = 'device-fingerprint-iframe';

    // Create the form
    let form = document.createElement('form');
    form.target = 'hidden_iframe'; // Set the target of the form to the iframe
    form.method = 'POST'; // Set the method of the form
    form.action = url; // Set the action of the form

    // Create the threeDSMethodData field
    let threeDSMethodData = document.createElement('input');
    threeDSMethodData.type = 'hidden'; // Hide the input field
    threeDSMethodData.name = 'threeDSMethodData'; // Set the name of the input field
    threeDSMethodData.value = btoa(JSON.stringify(data)); // Set the value of the input field

    // Add the threeDSMethodData field to the form
    form.appendChild(threeDSMethodData);
    this.iframe.appendChild(form);
    document.body.appendChild(this.iframe);
    form.submit();
    this.checkDeviceVerified(0, next_step);
  }

  formData(data) {
    let formData = new FormData();
    for (let key in data) {
      let name = 'card_authorization[' + key + ']'
      if (data[key] !== undefined) {
        formData.set(name, data[key]);
      }
    }
    return formData;
  }

  checkDeviceVerified(try_count, next_step) {
    let url = this.replaceRequestID(this.statusUrlValue)
    this.fetchJson(url, null, false, 'GET').then(response => {
      if (response.state === 'need_authorization') {
        next_step.bind(this)();
      } else if (response.state === 'need_device_fingerprint') {
        setTimeout(() => {
          this.checkDeviceVerified.bind(this)(try_count + 1, next_step);
        }, 500)
      } else if (response.state === 'error') {
        this.setError('Nepodařilo se ověřit zařízení - chyba při ověření zařízení');
      } else if (try_count > 10) {
        this.setError('Nepodařilo se ověřit zařízení - vypršel časový limit');
      } else if (response.state === 'authorized') {
        this.updateState(response)
      } else {
        this.setError('Nepodařilo se ověřit zařízení - neznámý stav : ' + response.state);
      }
    }).catch(error => {
      this.setError(error.message || 'Chyba při komunikaci s GIS');
    });
  }

  replaceRequestID(url) {
    return url.replace(':request_id', this.requestIdValue)
  }

  updateAuthorization(data) {
    let formData = this.formData(data)
    return this.fetchJson(this.replaceRequestID(this.updateUrlValue), formData, false, 'PATCH').then(response => {
      this.setState('loading', '')
    }).catch(error => {
      this.setError(error.message || 'Chyba při komunikaci s GIS')
    })
  }

  /*
  * Time difference between UTC time and the
  *Cardholder browser local time, in minutes.
   */
  timeDifference() {
    // Get the current time in the local time zone
    const localTime = new Date();
    // Get the current time in UTC
    const utcTime = new Date(localTime.toISOString());
    // Calculate the difference in milliseconds
    const differenceInMilliseconds = localTime - utcTime;
    // Convert the difference to minutes
    return differenceInMilliseconds / (1000 * 60);
  }

  create() {
    let data = this.formData({
      masked_card_number: '****' + this.cardNumber().slice(-4),
      owner_name: this.ownerNameTarget.value,
      card_name: this.hasNameTarget ? this.nameTarget.value : null,
      window_width: window.innerWidth,
      window_height: window.innerHeight,
      time_difference: this.timeDifference(),
      browser_language: navigator.language,
      card_number_hash: sha256(this.cardNumber()).toString(),
      kind: this.kindValue
    })
    return this.fetchJson(this.createUrlValue, data, false, 'POST')
  }

  isValidCardNumber(cardNumber) {
    const card = Card([visa, mastercard]);
    return card.isValid(cardNumber)
  }

  isSameCardOwner() {
    let normalized_original = this.clientNameValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();
    let normalized_input = this.ownerNameTarget.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();

    let normalized_original_parts = this.removeDegrees(normalized_original.split(' ')).sort();
    let normalized_input_parts = this.removeDegrees(normalized_input.split(' ')).sort();
    if (normalized_original_parts.length !== normalized_input_parts.length) {
      return false;
    }
    for (let i = 0; i < normalized_original_parts.length; i++) {
      if (normalized_original_parts[i] !== normalized_input_parts[i]) {
        return false;
      }
    }
    return true;
  }

  removeDegrees(array) {
    const degrees = ['bc.', 'mgr.', 'ing.', 'ph.d.', 'phdr.', 'thdr.', 'thlic.', 'judr.', 'mudr.', 'mvdr.', 'rndr.', 'csc.', 'drsc.', 'paeddr.', 'pharmdr.', 'rcdr.', 'artdr.', 'phmr.', 'thmgr.', 'doc.', 'prof.'];
    return array.filter(item => !degrees.includes(item));
  }

  setState(state, message) {
    let content = '';
    if (state === 'loading') {
      content = '<i class="fa fa-spinner fa-spin text-2xl"></i>';
    } else if (state === 'success') {
      content = '<i class="fa fa-check text-2xl"></i>';
    } else if (state === 'warning') {
      content = '<i class="fa fa-warning text-2xl"></i>';
    } else if (state === 'error') {
      content = '<i class="fa fa-triangle-exclamation text-2xl"></i>';
      this.showCloseOverlay(true);
    } else {
      content = '';
    }

    if (message) {
      content = content + ' ' + message;
    }

    this.overlayTextTarget.innerHTML = content;
  }

  showCloseOverlay(visible_button = true) {
    if (visible_button) {
      this.closeOverlayTarget.classList.remove('hidden');
    } else {
      this.closeOverlayTarget.classList.add('hidden');
    }
  }

  showOverlay(visible = true) {
    this.showCloseOverlay(false);
    if (visible) {
      this.overlayTarget.classList.remove('hidden');
    } else {
      this.overlayTarget.classList.add('hidden');
    }
  }

  checkState() {
    let url = this.replaceRequestID(this.statusUrlValue)
    this.fetchJson(url, null, false, 'GET').then(response => {
      this.updateState(response)
    }).catch(error => {
      this.setError(error.message || 'Chyba při komunikaci s GIS');
    })
  }

  updateState(response) {
    this.overlayTextTarget.innerHTML = response.html;
    if (response.state === 'authorized') {
      this.update_state = false;
    }
    if (response.state === 'error') {
      this.update_state = false;
    }
  }

  setError(message) {
    this.setState('error', message)
  }

  updateStateIfNeeded() {
    if (this.update_state) {
      this.checkState();
    }
  }

  close(e) {
    document.getElementById('card-authorization-dialog').close()
    e.preventDefault();
  }
}
